import styled from "styled-components";
import { Link } from "gatsby";

interface LinkProps {
  underline?: boolean;
}

export const Wrapper = styled.div`
  border-style: solid;
  border-color: #aaaaaa8e;
  border-width: 1px;
  background-color: #222222;
`;

export const Container = styled.div`
  margin: 0 auto;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 1440px;
`;

export const PaymentHolder = styled.div`
  width: 92px;
  height: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
export const Column = styled.div`
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: row;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 550px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

export const ItemHolder = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 210px;
`;

export const RightItemHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
`;

export const LogoHolder = styled.div`
  margin-top: 12px;
  width: 120px;
  height: 32px;
`;

export const CopyRight = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #999999;
  margin: 0;
  padding-bottom: 23px;
`;

export const ShortText = styled.p`
  padding-top: 55px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #999999;
  margin: 0 auto;
  padding-top: 55px;
  padding-bottom: 23px;
`;

export const Title = styled.h1`
  margin: 0;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #ffff;
`;
export const Description = styled.p`
  margin: 0;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #999999;
`;

export const AncerLink = styled.a<LinkProps>`
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #999999;
  text-decoration: none;
  text-decoration: ${(props) => props.underline && "underline"};
  text-underline-offset: 4px;
  :hover {
    cursor: pointer;
  }
`;

export const StyledLink = styled(Link)<LinkProps>`
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #999999;
  text-decoration: none;
  text-decoration: ${(props) => props.underline && "underline"};
  :hover {
    cursor: pointer;
  }
`;
export const PDFAssociationHolder = styled.div`
  width: 64px;
  height: 30px;
`;
export const UlFooter = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: 14px;
  color: #999999;
  font-size: 10px;
`;
export const ListFooter = styled.li`
  padding: 0;
`;
