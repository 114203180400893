import React from "react";
import styled from "styled-components";

const LoadingAnimation = styled.section`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    border: 20px solid #eaf0f6;
    border-radius: 50%;
    border-top: 20px solid #e44331;
    width: 100px;
    height: 100px;
    animation: spinner 2s linear infinite;
  }

  @media (max-width: 675px) {
    height: 85%;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const Container = styled.div`
  display: flex;
  margin: 210px auto;
  max-width: 80vw;
  max-width: 1440px;

  @media (max-width: 500px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

function Loader() {
  return (
    <Container>
      <LoadingAnimation>
        <div className="loader"></div>
      </LoadingAnimation>
    </Container>
  );
}

export default Loader;
