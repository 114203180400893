import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import styled from "styled-components";
import {
  StyledLink,
  StepHolder,
  StepHolderC,
  StepButtonB,
  CheckoutNavContainer,
  CheckoutNavDesktop,
  CheckoutSpanB,
  CheckoutNavMobileB,
} from "../NavbarStyled";
import { ReactComponent as PDFLogo } from "../../Icons/Logo PDF Pro 1.svg";
import { ResolvePlan } from "../../../../helpers/Dashboard";
import { useUserStore } from "../../../ZustandStores/UserSession/UserStore";
import useAuthStore from "../../../ZustandStores/UserSession/AuthenticationStore";
import { ReactComponent as DashIcon } from "../../Icons/long-lineB.svg";
import useActiveTabStoreCheckout from "../../../ZustandStores/CheckoutTabStore";
import CountryDropdown from "../../../Checkout/CountryDropdown/CountryDropdown";
import { navigate } from "gatsby";

const IconStyled = styled(DashIcon)`
  width: 40px;
`;

const CheckoutNavLinksC: React.FC = () => {
  const [width, setWidth] = React.useState(0);
  const location = useLocation();
  const [currentPathname, setCurrentPathname] = React.useState(
    location.pathname
  );
  const [lpNumberMatch, setLpNumberMatch] = React.useState(
    currentPathname.match(/\/lp(\d+)/)
  );
  const [lpNumber, setLpNumber] = React.useState(
    lpNumberMatch ? lpNumberMatch[1] : null
  );
  const [logoLinkDestination, setLogoLinkDestination] = React.useState(
    lpNumber ? `/lp${lpNumber}` : "/"
  );

  useEffect(() => {
    setLpNumber(lpNumberMatch ? lpNumberMatch[1] : null);
    setLogoLinkDestination(lpNumber ? `/lp${lpNumber}` : "/");
    setLpNumberMatch(currentPathname.match(/\/lp(\d+)/));
    setCurrentPathname(location.pathname);
  }, []);
  const { setActiveTabCheckout, activeTabsCheckout } =
    useActiveTabStoreCheckout();

  const { userLicenses } = useUserStore();
  const { authToken } = useAuthStore();

  useEffect(() => {
    console.log("Drop down: ", width);

    const PlanType = ResolvePlan(userLicenses);
    if (PlanType !== "Trial" && authToken) {
      navigate("/app/manage-subscription", { replace: true });
    }
    const handleBackButton = () => {
      if (activeTabsCheckout === 2) {
        setActiveTabCheckout(1);
      }
    };
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [activeTabsCheckout, setActiveTabCheckout]);

  return (
    <>
      <CheckoutNavContainer>
        <CheckoutNavDesktop>
          <StyledLink to={logoLinkDestination} aria-label="Home">
            <PDFLogo />
          </StyledLink>
          {/* <LogInWrapper></LogInWrapper> */}
          <StepHolder hide width={width}>
            <StepHolder
              onClick={() => {
                setActiveTabCheckout(1);
              }}
            >
              <StepButtonB active={activeTabsCheckout === 1}>1</StepButtonB>
              <CheckoutSpanB active={activeTabsCheckout === 1}>
                Choose Plan
              </CheckoutSpanB>
            </StepHolder>
            <DashIcon />
            <StepHolder>
              <StepButtonB
                active={activeTabsCheckout === 2}
                number={2}
                onClick={() => {
                  if (activeTabsCheckout === 3) {
                    setActiveTabCheckout(2);
                  }
                }}
              >
                2
              </StepButtonB>
              <CheckoutSpanB
                active={activeTabsCheckout === 2}
                onClick={() => {
                  if (activeTabsCheckout === 3) {
                    setActiveTabCheckout(2);
                  }
                }}
              >
                Plan Details
              </CheckoutSpanB>
            </StepHolder>
            <DashIcon />
            <StepHolder>
              <StepButtonB active={activeTabsCheckout === 3} number={3}>
                3
              </StepButtonB>
              <CheckoutSpanB active={activeTabsCheckout === 3}>
                Payment
              </CheckoutSpanB>
            </StepHolder>
          </StepHolder>

          <CountryDropdown setWidth={setWidth} />
          {/* <LogInWrapper>
            {!authToken && (
              <LogIn onClick={() => setLoginPdfOpen(true)}>
                Have an Account? <span>Log In</span>
              </LogIn>
            )}
            {authToken && (
              <LogOut
                onClick={() => {
                  clearAuthToken();
                  window.location.reload();
                }}
              >
                Log Out
              </LogOut>
            )}
          </LogInWrapper> */}
        </CheckoutNavDesktop>
        <CheckoutNavMobileB>
          <StepHolderC step2={activeTabsCheckout === 2}>
            <StepHolder>
              <StepButtonB
                active={activeTabsCheckout === 1}
                onClick={() => {
                  setActiveTabCheckout(1);
                }}
              >
                1
              </StepButtonB>
              <CheckoutSpanB active={activeTabsCheckout === 1}>
                Choose Plan
              </CheckoutSpanB>
              <IconStyled />
              <StepButtonB
                active={activeTabsCheckout === 2}
                number={2}
                onClick={() => {
                  if (activeTabsCheckout === 3) {
                    setActiveTabCheckout(2);
                  }
                }}
              >
                2
              </StepButtonB>
              <CheckoutSpanB
                active={activeTabsCheckout === 2}
                onClick={() => {
                  console.log("clicked");
                  if (activeTabsCheckout === 3) {
                    setActiveTabCheckout(2);
                  }
                }}
              >
                Plan Details
              </CheckoutSpanB>
              <IconStyled />
              <StepButtonB active={activeTabsCheckout === 3} number={3}>
                3
              </StepButtonB>
              <CheckoutSpanB active={activeTabsCheckout === 3}>
                Payment
              </CheckoutSpanB>
            </StepHolder>
          </StepHolderC>
        </CheckoutNavMobileB>
      </CheckoutNavContainer>
    </>
  );
};

export default CheckoutNavLinksC;
