import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(2, 22, 36, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const ModalContent = styled.div`
  max-width: 456px;
  width: 100%;
  padding: 40px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
  @media (max-width: 420px) {
    padding: 20px;
  }
  @media (max-width: 360px) {
    padding: 10px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 20px;
  padding: 32px;
  border-radius: 5px;
  background: #fff;
  #Close {
    position: relative;
    top: -32px;
    left: 100%;
    cursor: pointer;
  }
  @media (max-width: 420px) {
    #Close {
      top: -16px;
    }
  }

  @media (max-width: 360px) {
    #Close {
      top: 0;
    }
  }
`;

export const CheckElement = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const CheckLabel = styled.label`
  color: #414141;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.94px; /* 171% */
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 16px;
`;

export const Title = styled.div`
  color: #414141;
  /* font-family: Open Sans; */
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 31.92px; /* 133% */

  width: 100%;
  text-align: center;
  margin: 24px 0;
`;

export const SubTitle = styled.div`
  color: #6b6b6b;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-bottom: 24px;

  span {
    color: #6b6b6b;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
`;

export const Fieldset = styled.fieldset`
  border-radius: 8px;
  border: 1px solid #262626;
  background: #fff;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 16px;
  margin: 0;
  margin-bottom: 16px;
  position: relative;
  #Eye {
    max-width: 24px;
    max-height: 24px;
    cursor: pointer;
    /* fill: #4d73e8; */
    stroke: #4d73e8;
    stroke-width: 2px;
  }
`;

export const Legend = styled.legend`
  color: #262626;

  font-family: Work Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const InputStyle = styled.input`
  all: unset;
  width: 100%;
  color: #8c8c8c;

  /* Web Mobile/Body/Body */
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
`;

export const ButtonStyled = styled.button`
  all: unset;
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: 8px;
  background: #ed3833;

  color: #fff;
  text-align: center;

  /* Web Desktop/Buttons/Buttons */
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ForgotPassword = styled.div`
  display: flex;
  color: #36454f;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 8px;

  a {
    color: #0d9bff;

    text-align: right;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 23.94px; /* 171% */
    text-decoration-line: underline;
  }
`;

export const Error = styled.div`
  display: flex;
  color: #ba4b4b;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 8px;
  margin-bottom: 20px;
`;
