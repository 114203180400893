import { create } from "zustand";
import { setCookie, getCookie } from "../../../helpers/Cookies";

type UserStore = {
  userDetails: any;
  userLicenses: any;
  ocr: any;
  setUserDetails: (user: string) => void;
  setUserLicenses: (userLicenses: string) => void;
  payment: string;
  setPayment: (payment: string) => void;
  card: any;
  setCard: (card: any) => void;
  invoicesList: any;
  setInvoicesList: (invoicesList: any) => void;
  managers: any;
  setManagers: (managers: any) => void;
  subscription: any;
  setSubscription: (subscription: any) => void;
  setOcr: (ocr: boolean) => void;
  switchData: any;
  setSwitchData: (switchData: any) => void;
};

export const useUserStore = create<UserStore>((set) => {
  let userDetails = getCookie("userDetails");
  userDetails = userDetails ? JSON.parse(userDetails) : null;

  let userLicenses = "",
    card = "",
    payment = "",
    invoicesList = "",
    managers = "",
    subscription = "",
    ocr = "";

  if (typeof window !== "undefined") {
    userLicenses = localStorage.getItem("userLicenses") ?? "";
    userLicenses = userLicenses ? JSON.parse(userLicenses) : null;
    card = localStorage.getItem("card") ?? "";
    card = card ? JSON.parse(card) : null;
    payment = localStorage.getItem("payment") ?? "";
    payment = payment ? JSON.parse(payment) : null;
    invoicesList = localStorage.getItem("invoicesList") ?? "";
    invoicesList = invoicesList ? JSON.parse(invoicesList) : null;
    managers = localStorage.getItem("managers") ?? "";
    managers = managers ? JSON.parse(managers) : null;
    subscription = localStorage.getItem("subscription") ?? "";
    subscription = subscription ? JSON.parse(subscription) : null;
    ocr = localStorage.getItem("ocr") ?? "";
    ocr = ocr ? JSON.parse(ocr) : false;
  }

  return {
    userDetails: userDetails,
    userLicenses: userLicenses,
    card: card,
    payment: payment,
    invoicesList: invoicesList,
    managers: managers,
    subscription: subscription,
    ocr: ocr,
    switchData: {},
    setUserDetails: (user: string) =>
      set(() => {
        setCookie("userDetails", JSON.stringify(user), 8);

        return { userDetails: user };
      }),

    setUserLicenses: (licenses: string) =>
      set(() => {
        localStorage.setItem("userLicenses", JSON.stringify(licenses));

        return { userLicenses: licenses };
      }),

    setPayment: (payment: string) =>
      set(() => {
        localStorage.setItem("payment", JSON.stringify(payment));
        return { payment: payment };
      }),

    setCard: (card: any) =>
      set(() => {
        localStorage.setItem("card", JSON.stringify(card));
        return { card: card };
      }),

    setInvoicesList: (invoicesList: any) =>
      set(() => {
        localStorage.setItem("invoicesList", JSON.stringify(invoicesList));
        return { invoicesList: invoicesList };
      }),
    setManagers: (managers: any) =>
      set(() => {
        localStorage.setItem("managers", JSON.stringify(managers));
        return { managers: managers };
      }),
    setSubscription: (subscription: any) =>
      set(() => {
        localStorage.setItem("subscription", JSON.stringify(subscription));
        return { subscription: subscription };
      }),
    setOcr: (ocr: boolean) =>
      set(() => {
        localStorage.setItem("ocr", JSON.stringify(ocr));
        return { ocr: ocr };
      }),
    setSwitchData: (switchData: any) =>
      set(() => {
        return { switchData: switchData };
      }),
  };
});
