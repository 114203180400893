import { useEffect } from "react";
// import { ReactComponent as Close } from "../../Icons/Close.svg";
import usePopupsStore from "../../../ZustandStores/PopupsStore";
// import {
//   Button,
//   ModalContent,
//   ModalOverlay,
//   Rectangle,
//   Input,
//   Title,
//   Paragraph,
//   EmailMessage,
//   FlexBox,
//   Form,
//   Wrapper,
//   CloseIcon,
//   EmailExistsError,
//   Redirect,
// } from "./TryPdfStyled";
// import { navigate } from "gatsby";
// import Loader from "../../../ReusableComponents/loader";
// import MailSentNotification from "../../../ReusableComponents/MailSent/MailSentNotification";
// import { Strong } from "../../Shared.styles";
// import { getCookie, setCookie } from "../../../../helpers/Cookies";
// import { get } from "http";
import { WriteTrial } from "../../../../helpers/WriteTrial";

function TryPdf() {
  const { /* tryPdfOpen,*/ setTryPdfOpen } = usePopupsStore();
  // const [email, setEmail] = useState("");
  // const [isTouched, setIsTouched] = useState(false);
  // const [isValidEmail, setIsValidEmail] = useState(false);
  // const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  // const [emailExists, setEmailExists] = useState(false);
  // const [sentMessage, setSentMessage] = useState(false);
  // const [disableButton, setDisableButton] = useState(true);

  const closeModalHandler = () => {
    setTryPdfOpen(false);
    // setEmail("");
    // setEmailExists(false);
    // setIsValidEmail(true);
    // setIsEmptyEmail(false);
    // setSentMessage(false);
  };

  // const validateEmail = (email: string) => {
  //   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   return emailRegex.test(String(email).toLowerCase());
  // };

  // const handleBlur = () => {
  //   setIsTouched(true);
  //   setIsEmptyEmail(email.trim() === "");
  //   setIsValidEmail(validateEmail(email));
  // };

  // const handleSubmit = async () => {
  //   setIsTouched(true);
  //   setIsEmptyEmail(email.trim() === "");
  //   setIsValidEmail(validateEmail(email));
  //   if (isValidEmail) {
  //     try {
  //       const url =
  //         "https://1543d3a591e926011c7a-9b1b76dc96f52415d7c2bfd8c3a40309.ssl.cf2.rackcdn.com/PDFProInstaller.exe";
  //       const link = document.createElement("a");
  //       link.download = "file.pdf";
  //       link.href = url;
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       WriteTrial();
  //       const response = await fetch("/api/register-gatsby", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ email, company_code: "pdfpro" }),
  //       });
  //       if (response.ok) {
  //         const data = await response.json();
  //         console.log("Send the email");
  //         setCookie("source_trial", true, 24 * 30);
  //         setSentMessage(true);
  //       } else if (!response.ok) {
  //         navigate("/404");
  //       }
  //     } catch (error) {}
  //     setIsLoading(false);
  //   }
  // };

  // const HandleRedirectLogin = () => {
  //   navigate("/login");
  //   setTryPdfOpen(false);
  // };

  // const HandleRedirectPasswordResset = () => {
  //   navigate("/password-reset");
  //   setTryPdfOpen(false);
  // };

  // const EmailCheck = async (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault();
  //   let encoded = encodeURIComponent(email);

  //   try {
  //     setIsLoading(true);
  //     const response = await fetch(
  //       `/api/check-isEmailUsed-gatsby?email=${encoded}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     if (response.ok) {
  //       const data = await response.json();

  //       if (
  //         data.data.licenses_on_product_count.any ===
  //           data.data.licenses_on_product_count.trial &&
  //         data.data.licenses_on_product_count.any < 2
  //       ) {
  //         handleSubmit(); // Call RegisterHandler function
  //       } else {
  //         setEmailExists(true);
  //         setIsLoading(false);
  //       }
  //     } else if (!response.ok) {
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     navigate("/404");
  //   }
  // };

  // const handleInputChange = (e: any) => {
  //   setEmail(e.target.value);
  //   setEmailExists(false); // Reset emailExists state
  //   const isEmpty = email.trim() === "";
  //   const isValid = validateEmail(email);
  //   setIsTouched(true);
  //   setIsEmptyEmail(isEmpty);
  //   setIsValidEmail(isValid);
  //   setDisableButton(!isValid || isEmpty);
  // };

  // useEffect(() => {
  //   setDisableButton(!isValidEmail);
  // }, [isValidEmail]);

  useEffect(() => {
    const url =
      "https://1543d3a591e926011c7a-9b1b76dc96f52415d7c2bfd8c3a40309.ssl.cf2.rackcdn.com/PDFProInstaller.exe";
    const link = document.createElement("a");
    link.download = "file.pdf";
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    WriteTrial();
    closeModalHandler();
  }, []);

  return false;
  // return (
  //   <div>
  //     <ModalOverlay onClick={closeModalHandler}>
  //       <ModalContent onClick={(e) => e.stopPropagation()}>
  //         {isLoading ? (
  //           <Loader />
  //         ) : (
  //           <Wrapper>
  //             <Form>
  //               {sentMessage ? (
  //                 <MailSentNotification />
  //               ) : (
  //                 <>
  //                   <Title>
  //                     Try <Strong>PDF Pro </Strong> for 15 days!
  //                   </Title>
  //                   <Paragraph>
  //                     Register now to get a free demo copy of PDF Pro. You will
  //                     receive download and activation instructions by email.
  //                   </Paragraph>
  //                   <FlexBox>
  //                     <Input
  //                       placeholder="Email Address"
  //                       value={email}
  //                       onChange={handleInputChange}
  //                       onBlur={handleBlur}
  //                     />
  //                     <Button
  //                       as="button"
  //                       type="submit"
  //                       onClick={EmailCheck}
  //                       disabled={disableButton}
  //                     >
  //                       Get Trial
  //                     </Button>
  //                   </FlexBox>
  //                   {isTouched && emailExists && (
  //                     <EmailExistsError>
  //                       There is already an account associated with this email
  //                       address. Login{" "}
  //                       <Redirect onClick={HandleRedirectLogin}>here</Redirect>{" "}
  //                       or reset password{" "}
  //                       <Redirect onClick={HandleRedirectPasswordResset}>
  //                         here
  //                       </Redirect>
  //                       .
  //                     </EmailExistsError>
  //                   )}
  //                   {!emailExists && <EmailMessage />}
  //                 </>
  //               )}
  //               <Rectangle />
  //             </Form>
  //             <CloseIcon onClick={closeModalHandler}>
  //               <Close />
  //             </CloseIcon>
  //           </Wrapper>
  //         )}
  //       </ModalContent>
  //     </ModalOverlay>
  //   </div>
  // );
}

export default TryPdf;
