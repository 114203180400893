import React from 'react';
import './GlobalCss.css';
import Footer from '../GlobalComponetns/Footer/Footer';
import Navbar from '../GlobalComponetns/Header/Navbar';
import { Main, Wrapper } from './LayoutStyled';
import ContactsLp from '../landing-pages/contactsLp';
import { useEffect } from 'react';
import { setCookie, getCookie, getUtmParamsFromCookies } from '../../helpers/Cookies';
import Logo from './LogoPdf.png';
import styled from 'styled-components';
import ToolsCta from '../GlobalComponetns/SubHeaders/ToolsCta';
import { Link } from 'gatsby';
// import 'react-loading-skeleton/dist/skeleton.css';

interface Clsid {
  client_id: string;
  session_id: string;
}

declare global {
  interface Window {
    decorateURL?: (url: string) => string;
    clsid?: Clsid;
  }
}

const SeoImage = styled.img`
  display: none;
`;
type LayoutProps = {
  children: React.ReactNode;
  location?: any;
};

const specialPaths = [
  '/lp1/',
  '/lp1/checkout/',
  '/lp3/',
  '/lp3/checkout/',
  '/lp4/',
  '/lp4/checkout/',
  '/lp5/',
  '/lp5/checkout/',
  '/lp6/',
  '/lp6/checkout/',
  '/lp7/',
  '/lp7/checkout/',
  '/lp8/',
  '/lp8/checkout/',
  '/lp9/',
  '/lp9/checkout/',
  '/rm/',
  '/rm/checkout/',
  '/reactivate/',
  '/reactivate/checkout/',
];

const dashboardPaths = [
  '/dashboard/',
  '/finish-setup/',
  '/LoginPage/',
  '/register/',
];

const onlineToolsPaths = [
  '/merge-pdf/',
  '/rotate-pdf/',
  '/pdf-to-word/',
  '/extract-pdf-pages/',
  '/delete-pdf-pages/',
  '/split-pdf/',
  '/password-protect-pdf/',
  '/pdf-to-jpg/',
  '/pdf-to-png/',
  '/pdf-to-bmp/',
  '/pdf-to-excel/',
  '/jpg-to-pdf/',
  '/png-to-pdf/',
  '/tiff-to-pdf/',
  '/bmp-to-pdf/',
  '/excel-to-pdf/',
  '/pdf-to-csv/',
  '/pdf-to-xml/',
  '/pdf-to-html/',
  '/pdf-to-powerpoint/',
  '/powerpoint-to-pdf/',
  '/word-to-pdf/',
  '/rearrange-pdf-pages/',
  '/pdf-to-tiff/',
];

const Layout: React.FC<LayoutProps> = ({ children, location }) => {
  const isSpecialPage = specialPaths.includes(location?.pathname);
  const isDashboardPage = dashboardPaths.includes(location?.pathname);
  const isOnlineTool = onlineToolsPaths.includes(location?.pathname);

  function getUrlParameter(parameterName: any) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(parameterName);
  }

  function updateLinksAndForms(append: any) {
    // Update links
    const links = document.querySelectorAll('a[href*="sites.fastspring.com"]');
    links.forEach((link: any) => {
      const separator = link.href.includes('?') ? '&' : '?';
      link.href += separator + append;
    });

    // Update forms
    const forms = document.querySelectorAll(
      'form[action*="sites.fastspring.com"]'
    );
    forms.forEach((form: any) => {
      const separator = form.action.includes('?') ? '&' : '?';
      form.action += separator + append;
    });
  }

  useEffect(() => {
    const params = [
      'source',
      'utm_source',
      'utm_medium',
      'utm_content',
      'utm_campaign',
      'utm_term',
    ];

    params.forEach((param) => {
      const value = getUrlParameter(param);
      if (value) setCookie(param, value, 24);
    });

    let user_id: any = localStorage.getItem('user_id');

    if (!user_id) {
      user_id = Date.now();
      localStorage.setItem('user_id', user_id);
    }

    setCookie('user_id', user_id, 24 * 365);

    setCookie(
      'trial_utm_source',
      localStorage.getItem('trial_utm_source'),
      24 * 365
    );
    setCookie(
      'trial_utm_medium',
      localStorage.getItem('trial_utm_medium'),
      24 * 365
    );
    setCookie(
      'trial_utm_content',
      localStorage.getItem('trial_utm_content'),
      24 * 365
    );
    setCookie(
      'trial_utm_term',
      localStorage.getItem('trial_utm_term'),
      24 * 365
    );
    setCookie(
      'trial_utm_campaign',
      localStorage.getItem('trial_utm_campaign'),
      24 * 365
    );
    // setCookie("source", localStorage.getItem("source"), 24 * 365);

    const appendParams = ['source', 'campaign'];
    const append = appendParams
      .map((param) => {
        const value = getCookie(param);
        return value ? `${param}=${value}` : null;
      })
      .filter(Boolean)
      .join('&');

    if (append) updateLinksAndForms(append);
  }, []);

  const decorateURL = (url: string): string => {
    const utmParams = getUtmParamsFromCookies();
    const utmQuery = Object.entries(utmParams)
      .filter(([_, value]) => value)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    let queryParams = utmQuery;

    if (window.clsid) {
      const clsidParams = `clientId=${window.clsid.client_id}&sessionId=${window.clsid.session_id}`;
      queryParams += (queryParams ? "&" : "") + clsidParams;
    }

    return queryParams
      ? url + (url.includes("?") ? "&" : "?") + queryParams
      : url;
  };

  useEffect(() => {
    window.decorateURL = decorateURL;
  }, []);

  return (
    <Wrapper>
      <Navbar menuIsOpen={true} />
      {isOnlineTool && <ToolsCta />}
      <SeoImage src={Logo} alt="PDFPro Logo" />
      <Main>{children}</Main>
      {isSpecialPage ? <ContactsLp /> : isDashboardPage ? null : <Footer />}
    </Wrapper>
  );
};

export default Layout;
