import { useEffect, useState } from "react";

const BingScript = () => {
  const [didMount, setDidMount] = useState(false);

  // Logic for delaying the script insertion
  useEffect(() => {
    const delay = window.innerWidth <= 768 ? 3000 : 1500;
    setTimeout(() => setDidMount(true), delay);
    return () => setDidMount(false);
  }, []);

  // Logic for adding the script to the head
  useEffect(() => {
    if (didMount) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerHTML = `
      (function(w,d,t,r,u)
      {
        var f,n,i;
        w[u]=w[u]||[],f=function()
        {
          var o={ti:"4076835", enableAutoSpaTracking: true};
          o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
        },
        n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
        {
          var s=this.readyState;
          s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
        },
        i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
      })
      (window,document,"script","//bat.bing.com/bat.js","uetq");
   `;
      document.head.appendChild(script);
    }
  }, [didMount]);

  return null; // component doesn't render anything
};

export default BingScript;
