import React, { FC } from "react";
import { StyledLink, LinksContainer, HamburgerHolder } from "../NavbarStyled";
import { ReactComponent as PDFLogo } from "../../Icons/Logo PDF Pro 1.svg";
import { ReactComponent as HamburgerMenue } from "../../Icons/Hamburger.svg";
import { ReactComponent as CloseMenue } from "../../Icons/Close icon.svg";
export interface NavbarProps {
  MenuIsOpen: boolean;
  closeMenuHandler: () => void;
  OpenMenuHandler: () => void;
}

const LoginPageLinks: FC<NavbarProps> = ({ OpenMenuHandler, MenuIsOpen }) => {
  return (
    <>
      <StyledLink to="/" aria-label="Home" className="nav-link">
        <PDFLogo />
      </StyledLink>
      <LinksContainer>
        <StyledLink to="/register">Create Account</StyledLink>
      </LinksContainer>
      <HamburgerHolder onClick={OpenMenuHandler} menuState={MenuIsOpen}>
        {!MenuIsOpen ? <HamburgerMenue /> : <CloseMenue />}
      </HamburgerHolder>
    </>
  );
};

export default LoginPageLinks;
