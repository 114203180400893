import { create } from "zustand";
import { setCookie, deleteCookie, getCookie } from "../../../helpers/Cookies";
interface AuthState {
  authToken: string | null | undefined;
  setAuthToken: (token: string | null) => void;
  clearAuthToken: () => void;
}

const useAuthStore = create<AuthState>((set: any) => ({
  authToken: getCookie("authToken"),
  setAuthToken: (token: any) =>
    set(() => {
      if (typeof window !== "undefined") {
        if (token) {
          console.log("token: ", token);
          setCookie("authToken", token, 8);
          return { authToken: token };
        } else {
          return { authToken: null };
        }
      }
    }),
  clearAuthToken: () =>
    set((state: any) => {
      if (typeof window !== "undefined") {
        if (state.authToken) {
          deleteCookie("authToken");
          return { authToken: null };
        } else {
          return state;
        }
      }
    }),
}));

export default useAuthStore;
