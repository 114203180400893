import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../GlobalComponetns/Icons/LogoFooter.svg";
import { ReactComponent as Dashboard } from "./Icons/dashboard.svg";
import { ReactComponent as Download } from "./Icons/download.svg";
import { ReactComponent as Support } from "./Icons/contact_support.svg";
import { ReactComponent as Hamburger } from "./Icons/hamburger.svg";
import { ReactComponent as Manage } from "./Icons/groups.svg";
import { ReactComponent as Subscription } from "./Icons/text_snippet.svg";
import { ReactComponent as Billing } from "./Icons/billing.svg";
import { ReactComponent as Administrator } from "./Icons/person.svg";

import { RouteResolver } from "../../../helpers/RouteResolver";
import { ResolveAccountType } from "../../../helpers/Dashboard";
import { Link } from "gatsby";
import { useUserStore } from "../../ZustandStores/UserSession/UserStore";

interface WrapperProps {
  open?: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  transition: width 0.3s;
  overflow: hidden;
  width: 300px;

  min-width: 300px;

  background: rgb(68, 68, 68);
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    width: 100%;
    text-decoration: none;
  }

  svg {
    margin-top: 19px;
    margin-bottom: 64px;
    width: max-content;
    min-width: 24px;
  }

  @media (max-width: 768px) {
    min-width: auto;

    position: fixed;
    top: 0;
    left: 0;
    width: 240px;

    ${(props) => !props.open && `min-width: 0px;`}

    ${(props) => !props.open && `width: 0px;`}
  }
  z-index: 102;
`;

const WrapperPlaceholder = styled.div<WrapperProps>`
  opacity: 0;
  height: 100vh;
  transition: width 0.3s;
  overflow: hidden;
  width: 300px;

  min-width: 300px;

  background: rgb(68, 68, 68);
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    width: 100%;
    text-decoration: none;
  }

  svg {
    margin-top: 19px;
    margin-bottom: 64px;
    width: max-content;
    min-width: 24px;
  }

  @media (max-width: 768px) {
    min-width: auto;

    position: fixed;
    top: 0;
    left: 0;
    width: 240px;

    ${(props) => !props.open && `min-width: 0px;`}

    ${(props) => !props.open && `width: 0px;`}
  }
  z-index: 0;
`;

const Opener = styled.div`
  position: absolute;
  top: 15px;
  left: 24px;
  width: 40px;
  height: 40px;
  transition: all 0.3s;
  opacity: 1;
  cursor: pointer;
  z-index: 101;

  @media (min-width: 768px) {
    display: none;
    max-width: 0;
    max-height: 0;
  }
`;

interface DarkProps {
  open?: boolean;
  hideDark?: boolean;
}
const Dark = styled.div<DarkProps>`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s;

  width: 100%;
  height: 100%;
  opacity: 0;
  ${(props) => props.open && `opacity: 1;`}

  ${(props) => props.hideDark && `display: none`}
  ${(props) => props.hideDark && `max-width: 0;`}
  ${(props) => props.hideDark && `max-height: 0;`}

  z-index: 101;

  @media (min-width: 768px) {
    display: none;
    max-width: 0;
    max-height: 0;
  }
`;
interface MenuHolderProps {
  active?: boolean;
}
const MenuHolder = styled.div<MenuHolderProps>`
  height: 60px;
  width: 100%;
  min-width: 260px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  color: white;
  padding: 32px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 64px;
  ${(props) => props.active && `background-color: rgb(228, 67, 49);`}

  svg {
    fill: rgb(153, 153, 153);
    ${(props) => props.active && `fill: white;`}
    margin: 0;
  }
  :hover {
    background-color: rgb(228, 67, 49);
    svg {
      fill: white;
    }
  }
  @media (max-width: 768px) {
    min-width: 0px;
    padding-left: 32px;
  }
`;

const SubMenuHolder = styled.div<MenuHolderProps>`
  height: 60px;
  width: 100%;
  min-width: 260px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  color: white;
  padding: 32px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 80px;
  ${(props) => props.active && `color: rgb(228, 67, 49);`}

  svg {
    width: 24px;
    height: 24px;
    fill: rgb(153, 153, 153);
    ${(props) => props.active && `fill:  rgb(228, 67, 49);`}

    margin: 0;
  }
  :hover {
    background-color: rgb(196, 116, 108);
    color: white;
    svg {
      fill: white;
    }
  }
  @media (max-width: 768px) {
    min-width: 0px;
    padding-left: 48px;
  }
`;

interface MenuProps {
  location?: any;
}

function Menu({ location }: MenuProps) {
  const [open, setOpen] = useState(false);
  const [hideDark, setHideDark] = useState(true);
  const { userDetails, userLicenses } = useUserStore();

  let position = userLicenses?.results?.length === 1 ? 0 : 1;

  useEffect(() => {
    console.log(userDetails.true_email);
    console.log(userLicenses?.results[1]);
    if (open) {
      setHideDark(false);
    }
  }, [open]);

  let IsAdmin =
    userDetails.true_email === userLicenses?.results[1]?.customer?.email;

  let IsSingle = ResolveAccountType(userLicenses).includes("Single");
  return (
    <>
      <WrapperPlaceholder open={open} />
      <Wrapper open={open}>
        <Logo />
        <Link to="/app/dashboard">
          <MenuHolder active={RouteResolver(location, "dashboard")}>
            <Dashboard /> Dashboard
          </MenuHolder>
        </Link>
        {!userLicenses?.results[position]?.is_trial && (
          <Link
            to={!IsSingle ? "/app/manage-users" : "/app/manage-subscription/"}
          >
            <MenuHolder active={RouteResolver(location, "manage-")}>
              <Manage /> Account & Subscription
            </MenuHolder>
          </Link>
        )}
        {RouteResolver(location, "manage-") && (
          <>
            {!IsSingle && (
              <Link to="/app/manage-users">
                <SubMenuHolder active={RouteResolver(location, "manage-users")}>
                  <Manage /> Users
                </SubMenuHolder>
              </Link>
            )}
            {IsAdmin && (
              <Link to="/app/manage-administrator">
                <SubMenuHolder
                  active={RouteResolver(location, "manage-administrator")}
                >
                  <Administrator /> Administrator
                </SubMenuHolder>
              </Link>
            )}
            {userLicenses?.results?.length &&
              userLicenses?.results[1]?.license_type !== "perpetual" && (
                <Link to="/app/manage-subscription">
                  <SubMenuHolder
                    active={RouteResolver(location, "manage-subscription")}
                  >
                    <Subscription /> Subscription
                  </SubMenuHolder>
                </Link>
              )}
            <Link to="/app/manage-billing">
              <SubMenuHolder active={RouteResolver(location, "manage-billing")}>
                <Billing /> Billing
              </SubMenuHolder>
            </Link>
          </>
        )}
        <Link to="/download">
          <MenuHolder>
            <Download />
            Download
          </MenuHolder>
        </Link>
        <a
          href="https://support.pdfpro.com/hc/en-us/requests/new"
          rel="noreferrer noopener"
          target="_blank"
        >
          <MenuHolder>
            <Support />
            Support
          </MenuHolder>
        </a>
      </Wrapper>
      <Opener onClick={() => setOpen((o) => !o)}>
        <Hamburger />
      </Opener>

      <Dark
        hideDark={hideDark}
        open={open}
        onClick={() => {
          setOpen(false);
          setTimeout(() => setHideDark(true), 200);
        }}
      />
    </>
  );
}

export default Menu;
