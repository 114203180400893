import React, { useState, useEffect, createContext, ReactNode } from "react";

interface GlobalAuthContextProps {
  closedSticky: any;
  setClosedSticky: React.Dispatch<any>;
  isLoggedIn: any;
  setIsLoggedIn: React.Dispatch<any>;
  user: string;
  setUser: React.Dispatch<string>;
  authNumber: any;
  setAuthNumber: React.Dispatch<any>;
  globalPrimary: any;
  setGlobalPrimary: React.Dispatch<any>;
  activee: number;
  setActivee: React.Dispatch<number>;
  complete: number;
  setComplete: React.Dispatch<number>;
  step: number;
  setStep: React.Dispatch<number>;
  toggle: boolean;
  setToggle: React.Dispatch<boolean>;
  sideMenu: boolean;
  setSideMenu: React.Dispatch<boolean>;
  number: any;
  setNumber: React.Dispatch<any>;
  loader: any;
  setLoader: React.Dispatch<any>;
  activeLicenses: any;
  setActiveLicenses: React.Dispatch<any>;
  plan: any;
  setPlan: React.Dispatch<any>;
  licenses: any;
  setLicenses: React.Dispatch<any>;
  planType: string;
  setPlanType: React.Dispatch<string>;
  subscriptionPeriod: string;
  setSubscriptionPeriod: React.Dispatch<string>;
  scroll: boolean;
  setScroll: React.Dispatch<any>;
  userStatus: any;
  setUserStatus: React.Dispatch<any>;
  isLoadingApp: boolean;
  setIsLoadingApp: React.Dispatch<boolean>;
  showInfo: boolean;
  setShowInfo: React.Dispatch<boolean>;
  upgrade: boolean;
  setUpgrade: React.Dispatch<boolean>;
  selectedOption: any;
  setSelectedOption: React.Dispatch<any>;
}

export const GlobalAuthContext = createContext<GlobalAuthContextProps>({
  closedSticky: null,
  setClosedSticky: () => {},
  isLoggedIn: null,
  setIsLoggedIn: () => {},
  user: "",
  setUser: () => {},
  authNumber: null,
  setAuthNumber: () => {},
  globalPrimary: null,
  setGlobalPrimary: () => {},
  activee: 1,
  setActivee: () => {},
  complete: 0,
  setComplete: () => {},
  step: 0,
  setStep: () => {},
  toggle: false,
  setToggle: () => {},
  sideMenu: false,
  setSideMenu: () => {},
  number: null,
  setNumber: () => {},
  loader: undefined,
  setLoader: () => {},
  activeLicenses: null,
  setActiveLicenses: () => {},
  plan: null,
  setPlan: () => {},
  licenses: null,
  setLicenses: () => {},
  planType: "",
  setPlanType: () => {},
  subscriptionPeriod: "",
  setSubscriptionPeriod: () => {},
  scroll: false,
  setScroll: () => {},
  userStatus: undefined,
  setUserStatus: () => {},
  isLoadingApp: true,
  setIsLoadingApp: () => {},
  showInfo: true,
  setShowInfo: () => {},
  upgrade: false,
  setUpgrade: () => {},
  selectedOption: null,
  setSelectedOption: () => {},
});

interface GlobalContextProviderProps {
  children: ReactNode;
}

const GlobalContextProvider = ({ children }: GlobalContextProviderProps) => {
  const [closedSticky, setClosedSticky] = useState<any>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<any>(null);
  const [user, setUser] = useState<string>("");
  const [authNumber, setAuthNumber] = useState<any>(null);
  const [globalPrimary, setGlobalPrimary] = useState<any>(null);
  const [activee, setActivee] = useState<number>(1);
  const [complete, setComplete] = useState<number>(0);
  const [step, setStep] = useState<number>(0);
  const [toggle, setToggle] = useState<boolean>(false);
  const [sideMenu, setSideMenu] = useState<boolean>(false);
  const [number, setNumber] = useState<any>(null);
  const [loader, setLoader] = useState<any>(undefined);
  const [activeLicenses, setActiveLicenses] = useState<any>(null);
  const [licenses, setLicenses] = useState<any>(null);
  const [plan, setPlan] = useState<any>(null);
  const [planType, setPlanType] = useState<string>(() => "");
  const [subscriptionPeriod, setSubscriptionPeriod] = useState<string>(
    () => ""
  );
  const [scroll, setScroll] = useState<any>(false);
  const [userStatus, setUserStatus] = useState<any>(undefined);
  const [isLoadingApp, setIsLoadingApp] = useState<boolean>(true);
  const [showInfo, setShowInfo] = useState<boolean>(true);
  const [upgrade, setUpgrade] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);

  useEffect(() => {
    if (localStorage.getItem("username")) {
      setUser(JSON.parse(localStorage.getItem("username")!).first_name);
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [user]);

  const contextValue: GlobalAuthContextProps = {
    closedSticky,
    setClosedSticky,
    isLoggedIn,
    setIsLoggedIn,
    user,
    setUser,
    authNumber,
    setAuthNumber,
    globalPrimary,
    setGlobalPrimary,
    activee,
    setActivee,
    complete,
    setComplete,
    step,
    setStep,
    toggle,
    setToggle,
    sideMenu,
    setSideMenu,
    number,
    setNumber,
    loader,
    setLoader,
    activeLicenses,
    setActiveLicenses,
    plan,
    setPlan,
    licenses,
    setLicenses,
    planType,
    setPlanType,
    subscriptionPeriod,
    setSubscriptionPeriod,
    scroll,
    setScroll,
    userStatus,
    setUserStatus,
    isLoadingApp,
    setIsLoadingApp,
    showInfo,
    setShowInfo,
    upgrade,
    setUpgrade,
    selectedOption,
    setSelectedOption,
  };

  return (
    <GlobalAuthContext.Provider value={contextValue}>
      {children}
    </GlobalAuthContext.Provider>
  );
};

export default GlobalContextProvider;
