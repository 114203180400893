import React, { useState } from "react";
import { ReactComponent as Close } from "../../Icons/Close.svg";
import usePopupsStore from "../../../ZustandStores/PopupsStore";
import {
  ModalContent,
  ModalOverlay,
  Wrapper,
  Title,
  Row,
  SubTitle,
  InputStyle,
  ButtonStyled,
  ForgotPassword,
  Error,
  Fieldset,
  Legend,
  CheckElement,
  CheckLabel,
} from "./LoginPdfStyled";
import { navigate } from "gatsby";
import Loader from "../../../ReusableComponents/loader";
import { Link } from "gatsby";
import useAuthStore from "../../../ZustandStores/UserSession/AuthenticationStore";
import { useUserStore } from "../../../ZustandStores/UserSession/UserStore";
import { ResolvePlan } from "../../../../helpers/Dashboard";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { ReactComponent as EyeClosed } from "./Icons/EyeClosed.svg";
import { ReactComponent as EyeOpen } from "./Icons/EyeOpen.svg";

const ModalOverLayDepth = styled(ModalOverlay)`
  z-index: 9999;
`;

function LoginPdf() {
  const { setLoginPdfOpen } = usePopupsStore();
  const { setAuthToken } = useAuthStore();
  const { setUserDetails, setUserLicenses } = useUserStore();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const LoginHandler = async (e: any) => {
    e.preventDefault();
    if (email && password) {
      try {
        setIsLoading(true);
        const response = await fetch("/api/login-gatsby", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        });
        if (response.ok) {
          const data = await response.json();
          setUserDetails(data.data.user);
          let token = data.data.token;
          fetch("/api/licenses-gatsby", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${data.data.token}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              setLoginPdfOpen(false);
              let planType = ResolvePlan(data.data);
              console.log("planType: ", planType);
              let loc = window.location.href;
              if (planType !== "Trial" && loc?.includes("checkout")) {
                navigate("/app/manage-subscription/");
              }

              setUserLicenses(data.data);
              setAuthToken(token);
            })
            .catch((error) => {
              console.error("Error fetching licenses:", error);
              setError(true);
              setIsLoading(false);
            });
        } else if (!response.ok) {
          setError(true);
          setIsLoading(false);
        }
      } catch (error) {
        setError(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <ModalOverLayDepth onClick={() => setLoginPdfOpen(false)}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          {isLoading ? (
            <Loader />
          ) : (
            <Wrapper>
              <Close
                onClick={() => {
                  setLoginPdfOpen(false);
                }}
                id="Close"
              />
              <StaticImage src="./images/login.png" alt="login" />
              <Title>Log in to PDF Pro</Title>
              <SubTitle>
                To start working on your documents, enter your
                <span> PDF Pro</span> credentials.
              </SubTitle>
              <Fieldset>
                <Legend>Email: *</Legend>
                <InputStyle
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                />
              </Fieldset>
              <Fieldset>
                <Legend>Password: *</Legend>
                <InputStyle
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  value={password}
                  onChange={(e: any) => setPassword(e.target.value)}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === "Enter") {
                      LoginHandler(e);
                    }
                  }}
                />
                {!showPassword && (
                  <EyeClosed id="Eye" onClick={() => setShowPassword(true)} />
                )}
                {showPassword && (
                  <EyeOpen id="Eye" onClick={() => setShowPassword(false)} />
                )}
              </Fieldset>

              {error && <Error>Incorrect password or email</Error>}
              <Row>
                <CheckElement>
                  <input type="checkbox" />
                  <CheckLabel> Don't remember me</CheckLabel>
                </CheckElement>
                <ForgotPassword>
                  <Link
                    to="/password-reset/"
                    onClick={() => setLoginPdfOpen(false)}
                  >
                    Forgot password?
                  </Link>{" "}
                </ForgotPassword>
              </Row>
              <ButtonStyled onClick={LoginHandler}>Log in</ButtonStyled>
            </Wrapper>
          )}
        </ModalContent>
      </ModalOverLayDepth>
    </div>
  );
}

export default LoginPdf;
