import { create } from 'zustand';

export enum AllowedPaths {
  PdfOneYear = 'pdf-one-year',
  PdfTwoYear = 'pdf-two-year',
  PdfMonthly = 'pdf-monthly',
  PdfPerpetual = 'pdf-perpetual',
}

type CheckoutStore = {
  ProductPath: string;
  Ocr: boolean;
  RealPath: string;
  ProductDiscount: number | null;
  OcrDiscount: number | null;
  ProductPathDiscount: string | null;
  LocationPathDiscount: string;
  Amount: number;
  email: string;
  fname: string;
  lname: string;
  paymentGateway: string;
  phoneNumber: string;
  cardNumber: string;
  emailSubscription: boolean;
  expirationDate: string;
  securityCode: string;
  zipCode: string;
  lockLanguage: boolean;

  setPath: (id: AllowedPaths) => void;
  setAmount: (id: number) => void;
  setOcr: (id: boolean) => void;
  setFname: (fname: string) => void;
  setLname: (lname: string) => void;
  setEmail: (lname: string) => void;
  setPaymentGateway: (lname: string) => void;
  setPhoneNumber: (lname: string) => void;
  setEmailSubscription: (lname: boolean) => void;
  setCardNumber: (lname: string) => void;
  setExpirationDate: (lname: string) => void;
  setSecurityCode: (lname: string) => void;
  setZipCode: (lname: string) => void;
  setProductDiscount: (discount: number | null) => void;
  setOcrDiscount: (discount: number | null) => void;
  setProductPathDiscount: (discount: string | null) => void;
  setLocationPathDiscount: (discount: string) => void;
  setLockLanguage: (lock: boolean) => void;
};

const pathMapping: Record<AllowedPaths, string> = {
  [AllowedPaths.PdfOneYear]: 'pdf-one-year',
  [AllowedPaths.PdfTwoYear]: 'pdf-two-year',
  [AllowedPaths.PdfMonthly]: 'pdf-monthly',
  [AllowedPaths.PdfPerpetual]: 'pdf-perpetual',
};

const useCheckout = create<CheckoutStore>((set) => ({
  RealPath: 'pdf-one-year-multi-ocr',
  ProductPath: 'pdf-one-year',
  Ocr: false,
  Amount: 2,
  email: '',
  fname: '',
  lname: '',
  phoneNumber: '',
  cardNumber: '',
  emailSubscription: false,
  expirationDate: '',
  securityCode: '',
  zipCode: '',
  paymentGateway: '',

  setPath: (path: AllowedPaths) => {
    let realPath = pathMapping[path];
    set((state) => {
      if (state.Amount > 1 && location.pathname !== '/reactivate/checkout/') {
        realPath = realPath + '-multi';
      }
      if (state.Ocr) {
        realPath = realPath + '-ocr';
      }
      if (location.pathname === '/reactivate/checkout/') {
        realPath = realPath + '-reactivation';
      }
      return { ...state, ProductPath: path, RealPath: realPath };
    });
  },
  setEmail: (email: string) => set({ email: email }),
  setFname: (fname: string) => set({ fname: fname }),
  setLname: (lname: string) => set({ lname: lname }),
  setPhoneNumber: (phoneNumber: string) => set({ phoneNumber: phoneNumber }),
  setCardNumber: (cardNumber: string) => set({ cardNumber: cardNumber }),
  setExpirationDate: (expirationDate: string) =>
    set({ expirationDate: expirationDate }),
  setSecurityCode: (securityCode: string) =>
    set({ securityCode: securityCode }),
  setZipCode: (zipCode: string) => set({ zipCode: zipCode }),
  setEmailSubscription: (emailSubscription: boolean) =>
    set({ emailSubscription: emailSubscription }),
  setPaymentGateway: (paymentGateway: string) =>
    set({ paymentGateway: paymentGateway }),
  setAmount: (amount: number) => {
    set((state) => {
      let realPath = state.ProductPath;
      if (amount > 1 && location.pathname !== '/reactivate/checkout/') {
        realPath = realPath + '-multi';
      }
      if (state.Ocr) {
        realPath = realPath + '-ocr';
      }
      if (location.pathname === '/reactivate/checkout/') {
        realPath = realPath + '-reactivation';
      }
      return { ...state, RealPath: realPath, Amount: amount };
    });
  },

  setOcr: (ocr: boolean) => {
    set((state) => {
      let realPath = state.ProductPath;
      if (state.Amount > 1 && location.pathname !== '/reactivate/checkout/') {
        realPath = realPath + '-multi';
      }
      if (ocr) {
        realPath = realPath + '-ocr';
      }
      if (location.pathname === '/reactivate/checkout/') {
        realPath = realPath + '-reactivation';
      }
      return { ...state, RealPath: realPath, Ocr: ocr };
    });
  },
  ProductDiscount: null,
  OcrDiscount: null,
  setProductDiscount: (discount: number | null) =>
    set({ ProductDiscount: discount }),
  setOcrDiscount: (discount: number | null) => set({ OcrDiscount: discount }),
  ProductPathDiscount: '',
  LocationPathDiscount: '',
  setLocationPathDiscount: (discount: string) =>
    set({ LocationPathDiscount: discount }),
  setProductPathDiscount: (discount: string | null) =>
    set({ ProductPathDiscount: discount }),
  lockLanguage: false,
  setLockLanguage: (lock: boolean) => set({ lockLanguage: lock }),
}));

export default useCheckout;
