import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Flag from "./Flag";
import { countryInfo } from "../../../helpers/PriceCalculator";
import DropdownLocation from "./DropdownLocation";
import { countryTagList } from "../../../helpers/CountryTags";
import { ReactComponent as Down } from "../Icons/chevron-down.svg";
import useCheckout from "../../ZustandStores/CheckoutStore";
import useLanguage from "../../ZustandStores/LanguageStore";
interface Option {
  value: string;
  label: string;
}

const options: Option[] = Object.entries(countryTagList).map(
  ([value, label]): Option => ({
    value,
    label,
  })
);

const MainStyle = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  cursor: pointer;

  svg {
    min-width: 24px;
    min-height: 24px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 0;
  overflow: visible;
  position: relative;
  right: 15%;
  @media (max-width: 920px) {
    right: auto;

    max-width: max-content;
  }
`;
const CountryText = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  color: rgba(0, 0, 0, 0.6);
  margin-left: 12px;
  min-width: max-content;
  width: max-content;
  margin-right: 16px;
  @media (max-width: 640px) {
    display: none;
  }
`;

interface Props {
  setWidth?: any;
}
function CountryDropdown({ setWidth }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { lockLanguage } = useCheckout();
  const { language } = useLanguage();

  const [info, setInfo] = useState<any>(() => {
    let info = countryInfo();
    if (info?.countryTag === "US") {
      info.countryName = "United States";
    }
    return info;
  });

  const holder = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (setWidth) {
      setWidth(holder.current?.offsetWidth || 0);
    }
  }, []);

  useEffect(() => {
    let info = countryInfo();
    if (info?.countryTag === "US") {
      info.countryName = "United States";
    }
    setInfo(info);
  }, [language]);

  return (
    <MainWrapper>
      <MainStyle onClick={() => setIsOpen(!isOpen)} ref={holder}>
        <Flag tag={info?.countryTag} />
        <CountryText>{info?.countryName}</CountryText>
        {!lockLanguage && <Down />}
      </MainStyle>

      {isOpen && !lockLanguage && (
        <DropdownLocation
          options={options}
          setOpen={setIsOpen}
          width={holder.current?.offsetWidth || 0}
        />
      )}
    </MainWrapper>
  );
}

export default CountryDropdown;
