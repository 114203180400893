import React, { useEffect } from "react";
import "./GlobalCss.css";
import styled from "styled-components";
import Menu from "./DashboardMenu/Menu";
import DashboardHeader from "./DashboardMenu/DashboardHeader";
import { useUserStore } from "../ZustandStores/UserSession/UserStore";
import { getCookie } from "../../helpers/Cookies";
import usePopupsStore from "../ZustandStores/PopupsStore";
import AssignNewUser from "./AssignNewUser";
import IncreaseUsers from "./IncreseUsers";
import { useLocation } from "@reach/router";
import AssignNewManager from "./AssignNewManager";
import ChangeSubscription from "./ChangeSubscription";
const WrapperDash = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;
`;

type LayoutProps = {
  children: React.ReactNode;
  location?: any;
};
const LayoutDashboard: React.FC<LayoutProps> = ({ children }) => {
  const { setUserLicenses } = useUserStore();
  const location = useLocation();

  const {
    assignUserOpen,
    increaseUsersOpen,
    assignManagerOpen,
    switchSubscriptionOpen,
  } = usePopupsStore();

  useEffect(() => {
    let token = getCookie("authToken");
    fetch("/api/licenses-gatsby", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUserLicenses(data.data);
      })
      .catch((error) => {
        console.error("Error fetching licenses:", error);
      });
  }, []);

  return (
    <WrapperDash>
      {assignUserOpen && <AssignNewUser />}
      {increaseUsersOpen && <IncreaseUsers />}
      {location.pathname === "/app/password-change/" ? null : (
        <Menu location={location} />
      )}
      {assignManagerOpen && <AssignNewManager />}
      {switchSubscriptionOpen && <ChangeSubscription />}
      <Column>
        <DashboardHeader />
        {children}
      </Column>
    </WrapperDash>
  );
};

export default LayoutDashboard;
