import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import styled from "styled-components";
import {
  StyledLink,
  StepHolder,
  StepButton,
  StepButton2,
  CheckoutNavContainer,
  CheckoutNavDesktop,
  CheckoutNavMobile,
  CheckoutSpan,
} from "../NavbarStyled";
import { ReactComponent as PDFLogo } from "../../Icons/Logo PDF Pro 1.svg";
import { ResolvePlan } from "../../../../helpers/Dashboard";
import { useUserStore } from "../../../ZustandStores/UserSession/UserStore";
import useAuthStore from "../../../ZustandStores/UserSession/AuthenticationStore";
import { ReactComponent as DashIcon } from "../../Icons/long-line.svg";
import useActiveTabStoreCheckout from "../../../ZustandStores/CheckoutTabStore";
import CountryDropdown from "../../../Checkout/CountryDropdown/CountryDropdown";
import { navigate } from "gatsby";

const IconStyled = styled(DashIcon)`
  width: 10px;
`;

const CheckoutNavLinks: React.FC = () => {
  const [width, setWidth] = React.useState(0);

  const location = useLocation();
  const currentPathname = location.pathname;
  const lpNumberMatch = currentPathname.match(/\/lp(\d+)/);
  const [isReactivationPage, setIsReactivationPage] = useState(false);

  const lpNumber = lpNumberMatch ? lpNumberMatch[1] : null;
  let logoLinkDestination = lpNumber ? `/lp${lpNumber}` : "/";

  if (currentPathname.includes("/rm")) {
    logoLinkDestination = `/rm`;
  }

  if (currentPathname.includes("/reactivate")) {
    logoLinkDestination = `/reactivate`;
  }

  const { setActiveTabCheckout, activeTabsCheckout } =
    useActiveTabStoreCheckout();

  const { userLicenses } = useUserStore();
  const { authToken } = useAuthStore();
  useEffect(() => {
    const PlanType = ResolvePlan(userLicenses);
    if (PlanType !== "Trial" && authToken) {
      navigate("/app/manage-subscription", { replace: true });
    }

    const handleBackButton = () => {
      if (activeTabsCheckout === 2) {
        setActiveTabCheckout(1);
      }
    };
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [activeTabsCheckout, setActiveTabCheckout]);

  useEffect(() => {
    if (currentPathname.includes("/reactivate/checkout")) {
      setIsReactivationPage(true);
    }
  }, []);
  
  return (
    <>
      <CheckoutNavContainer>
        <CheckoutNavDesktop>
          <StyledLink to={logoLinkDestination} aria-label="Home">
            <PDFLogo />
          </StyledLink>
          {!isReactivationPage && (
            <StepHolder hide width={width}>
              <StepHolder
                onClick={() => {
                  setActiveTabCheckout(1);
                }}
              >
                <StepButton activeTabsCheckout={activeTabsCheckout}>
                  1
                </StepButton>
                <CheckoutSpan>Choose Plan</CheckoutSpan>
              </StepHolder>
              <DashIcon />
              <StepHolder>
                <StepButton2 activeTabsCheckout={activeTabsCheckout}>
                  2
                </StepButton2>
                <CheckoutSpan>Payment</CheckoutSpan>
              </StepHolder>
            </StepHolder>
          )}
          <CountryDropdown setWidth={setWidth} />
        </CheckoutNavDesktop>
        <CheckoutNavMobile>
          {!isReactivationPage && (
            <StepHolder>
              <StepHolder>
                <StepButton
                  activeTabsCheckout={activeTabsCheckout}
                  onClick={() => {
                    setActiveTabCheckout(1);
                  }}
                >
                  1
                </StepButton>
                <span>Choose Plan</span>
                <IconStyled />
                <StepButton2 activeTabsCheckout={activeTabsCheckout}>
                  2
                </StepButton2>
                <span>Payment</span>
              </StepHolder>
            </StepHolder>
          )}
        </CheckoutNavMobile>
      </CheckoutNavContainer>
    </>
  );
};

export default CheckoutNavLinks;
