export function setCookie(name: string, value: any, hours: number) {
  if (typeof window !== "undefined") {
    let expires = "";
    if (hours) {
      const date = new Date();
      date.setTime(date.getTime() + hours * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
}

export function deleteCookie(name: string) {
  if (typeof window !== "undefined") {
    document.cookie = name + "=; Max-Age=0; path=/";
  }
}

export function getCookie(name: string) {
  if (typeof window !== "undefined") {
    const value = "; " + document.cookie;
    const parts: string[] = value.split("; " + name + "=");

    if (parts.length === 2) return parts.pop()?.split(";").shift();
  }
  return null;
}

export function getUtmParamsFromCookies() {
  const utmParams = ["utm_source", "utm_campaign", "utm_medium", "utm_term"];
  const params: { [key: string]: string | null } = {};

  utmParams.forEach((param) => {
    const cookieValue = getCookie(param);
    if (cookieValue !== undefined) {
      params[param] = cookieValue;
    } else {
      params[param] = null;
    }
  });

  return params;
}
