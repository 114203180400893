import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 110;
  box-sizing: border-box;
`;

export const Modal = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  padding: 48px 48px;
  background: white;
  border-radius: 24px;
  box-sizing: border-box;
  .close {
    position: relative;
    top: -16px;
    left: 98%;
    cursor: pointer;
  }

  h1 {
    line-height: 48px;
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
  }
`;

export const Button = styled.button`
  all: unset;
  width: max-content;
  padding: 8px 16px;
  border-radius: 32px;
  background-color: rgb(0, 202, 66);
  font-size: 18px;
  color: white;
  margin-top: 16px;
  cursor: pointer;
  font-weight: 500;
  box-sizing: border-box;

  :hover {
    background-color: rgb(7, 216, 76);
  }
`;
