import styled from "styled-components";
import { Link } from "gatsby";
interface LinksContainerProps {
  menuState?: boolean;
  checkout?: boolean;
}

interface WrapperProps {
  checkout?: boolean;
}

interface ButtonProps {
  activeTabsCheckout?: number;
}
interface StepProps {
  hide?: boolean;
  width?: number;
}

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;

  background-color: #fff;
  z-index: 100;

  border-bottom: solid;
  border-color: #aaaaaa8e;
  border-width: 1px;

  @media (max-width: 925px) {
    height: ${(props) => props.checkout && "110px"};
  }
`;

export const LogIn = styled.div`
  white-space: nowrap;
  color: #36454f;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: none;
  text-transform: capitalize;
  cursor: pointer;

  span {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #0d9bff;
  }
`;

export const LogOut = styled.div`
  width: 100px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #0d9bff;
  background: #fff;
  color: #0d9bff;
  text-align: center;
  font-family: Work Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 11px 16px;
  cursor: pointer;

  transform: translateY(-12px);
`;

export const LogInWrapper = styled.div`
  position: relative;
  right: 10%;
  top: 8px;
  max-width: 0;
  max-height: 0;
  overflow: visible;
`;

export const Container = styled.div<LinksContainerProps>`
  width: 80vw;
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 875px) {
    max-width: ${(props) => props.menuState && "90vw"};
  }
`;

export const LinksContainer = styled.div<LinksContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: 1110px) {
    display: none;
  }
`;

export const HamburgerHolder = styled.div<LinksContainerProps>`
  display: none;

  @media (max-width: 1110px) {
    display: block;
  }
`;

export const AncerLink = styled.a`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #222222;
  text-decoration: none;

  @media (max-width: 875px) {
    font-weight: 400;
    font-size: 20px;

    line-height: 27px;
  }

  :hover {
    color: rgba(34, 34, 34, 0.6);
  }
`;

export const StyledLink = styled(Link)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #222222;

  text-decoration: none;
  display: flex;
  align-items: center;

  :hover {
    color: rgba(34, 34, 34, 0.6);
  }

  @media (max-width: 875px) {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
  }
`;

export const Button = styled.button`
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  align-items: center;
  width: 160px;
  height: 42px;
  background: rgb(228, 67, 49);
  border-radius: 60px;
  margin: 0px;
  padding: 0px;
  color: white;
  border: none;

  :hover {
    cursor: pointer;
    background: rgb(244, 53, 32);
  }
`;

export const FlagHolder = styled.div`
  width: 44px;
  height: 32px;
`;

export const Country = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 10px;
`;

export const StepHolder = styled.div<StepProps>`
  display: flex;
  align-items: center;
  gap: 10px;

  ${(props) => props.width && `transform: translateX(-${props.width / 2}px)`};

  @media (max-width: 925px) {
    font-size: 14px;
    display: ${(props) => props.hide && "none"};
  }
  @media (max-width: 380px) {
    font-size: 14px;
    white-space: nowrap;
  }
  cursor: pointer;
`;

interface StepHolderBProps {
  step2?: boolean;
}

export const StepHolderB = styled(StepHolder)<StepHolderBProps>`
  position: absolute;
  left: 0;
  top: 64px;
  width: 100vw;
  display: flex;
  justify-content: center;
  background: #ececee;
  height: 48px;
  ${(props) => props.step2 && `background: #F7F7F8;`};
`;

export const StepHolderC = styled(StepHolder)<StepHolderBProps>`
  position: absolute;
  left: 0;
  top: 64px;
  width: 100vw;
  display: flex;
  justify-content: center;
  background: #e1f0ff;
  height: 48px;
  /* ${(props) => props.step2 && `background: #F7F7F8;`}; */
`;

export const StepButton = styled.button<ButtonProps>`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  border: 1px solid #fb4a38;
  color: ${({ activeTabsCheckout }) =>
    activeTabsCheckout === 2 ? "#fff" : "#fb4a38"};
  background: ${({ activeTabsCheckout }) =>
    activeTabsCheckout === 2 ? "#fb4a38" : "#ffff"};

  @media (max-width: 470px) {
    width: 20px;
    height: 20px;
  }
`;

interface StepButtonBProps {
  active?: boolean;
  number?: number;
}

export const StepButtonB = styled.button<StepButtonBProps>`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  border: 1px solid #fb4a38;
  font-weight: bold;
  color: ${({ active, number }) =>
    !active ? (number === 2 || number === 3 ? "#999999" : "#fff") : "#fff "};
  background: ${({ active, number }) =>
    !active ? (number === 2 || number === 3 ? "#fff" : "#999999") : "#fb4a38"};

  ${({ active }) => !active && "border: 1px solid #999999"};

  @media (max-width: 470px) {
    width: 20px;
    height: 20px;
  }
`;

export const StepButton2 = styled.button<ButtonProps>`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  color: #fb4a38;
  border: ${({ activeTabsCheckout }) =>
    activeTabsCheckout === 2
      ? "1px solid #fb4a38"
      : "1px solid rgba(0, 0, 0, 0.4)"};
  color: ${({ activeTabsCheckout }) =>
    activeTabsCheckout === 2 ? "#fb4a38" : "rgba(0, 0, 0, 0.4)"};
  background: ${({ activeTabsCheckout }) =>
    activeTabsCheckout === 2 ? "white" : "white"};

  @media (max-width: 470px) {
    width: 20px;
    height: 20px;
  }
`;

export const CheckoutNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;

  gap: 15px;
`;

export const CheckoutNavDesktop = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const CheckoutNavMobile = styled.div`
  display: flex;
  justify-content: space-between;
  display: none;

  @media (max-width: 925px) {
    display: flex;
    justify-content: center;
  }
`;

export const CheckoutNavMobileB = styled.div`
  display: flex;
  justify-content: space-between;
  display: none;
  height: 26px;
  @media (max-width: 925px) {
    display: flex;
    justify-content: center;
  }
`;

export const CheckoutSpan = styled.span``;

interface CheckoutSpanProps {
  active?: boolean;
}
export const CheckoutSpanB = styled.span<CheckoutSpanProps>`
  color: ${({ active }) => (active ? "#fb4a38" : "#999999")};
`;
