import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useUserStore } from "../../ZustandStores/UserSession/UserStore";
import { ReactComponent as Arrow } from "./Icons/arrow_open.svg";
import { Link } from "gatsby";
import useAuthStore from "../../ZustandStores/UserSession/AuthenticationStore";
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #222222;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 875px) {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
  }
`;

interface DropButtonProps {
  open: boolean;
}
const DropButton = styled.div<DropButtonProps>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  svg {
    transition: 0.3s;
    transform: ${(props) => (props.open ? "rotate(-180deg)" : "rotate(0deg)")};
    min-width: 16px;
    stroke: #222222;

    path {
      stroke-width: 3;
    }
  }
`;

interface MenuProps {
  open: boolean;
}

const Menu = styled.div<MenuProps>`
  display: flex;
  flex-direction: column;
  transition: height 0.3s, padding 0.3s, opacity 0.5s;
  overflow: hidden;
  width: 200px;
  height: 140px;
  opacity: 1px;
  transform: translateX(-58px);
  border-radius: 8px;

  padding: 24px;
  ${(props) => props.open === false && "height: 0px;"};

  ${(props) => props.open === false && "padding: 0px 24px ;"};
  ${(props) => props.open === false && "opacity: 0;"};

  position: absolute;
  top: 64px;
  gap: 4px;

  background: rgb(255, 255, 255);
  border: 1px solid rgb(216, 216, 216);
  a {
    text-decoration: none;
  }
`;

const MenuText = styled(Text)`
  font-size: 16px;
  cursor: pointer;
  :hover {
    color: rgba(34, 34, 34, 0.6);
  }
`;

const IconHolder = styled.div`
  max-width: 30px;
  min-height: 7px;
  display: flex;
  align-items: center;
`;

function UserDropdown() {
  const [open, setOpen] = useState(false);
  const { userDetails } = useUserStore();
  const { clearAuthToken } = useAuthStore();
  const dropdownRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <Wrapper ref={dropdownRef}>
      <DropButton open={open} onClick={() => setOpen((e) => !e)}>
        <Text>
          {userDetails?.first_name}
          <IconHolder>
            <Arrow />
          </IconHolder>
        </Text>
      </DropButton>
      <Menu open={open}>
        <Link
          to="/app/dashboard"
          onClick={() => {
            setOpen(false);
          }}
        >
          <MenuText>Dashboard</MenuText>
        </Link>
        <Link
          to="/app/password-change"
          onClick={() => {
            setOpen(false);
          }}
        >
          <MenuText>Change Password</MenuText>
        </Link>
        <Link
          to="/login"
          onClick={() => {
            clearAuthToken();
            setOpen(false);
          }}
        >
          <MenuText>Sign Out</MenuText>
        </Link>
      </Menu>
    </Wrapper>
  );
}

export default UserDropdown;
