import React, { useEffect, useState } from "react";
import styled from "styled-components";

const FlagStyle = styled.div`
  max-width: 48px;
  max-height: 32px;
  min-height: 32px;

  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = styled.div`
  min-width: 43px;
  max-height: 32px;
  min-height: 32px;
  background: #f2f2f2;
`;

interface FlagProps {
  tag: string;
}

function Flag({ tag }: FlagProps) {
  const [IconComponent, setIconComponent] = useState<React.FC | null>(null);

  useEffect(() => {
    async function importIcon() {
      const ImportedIcon = (await import(`../images/flags/${tag}.svg`))
        .ReactComponent;
      setIconComponent(() => ImportedIcon);
    }
    importIcon();
    console.log("Icon: ", tag);
  }, [tag]);

  return (
    <div>
      {IconComponent ? (
        <FlagStyle>
          <IconComponent />
        </FlagStyle>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Flag;
