import styled from "styled-components";
import { Link } from "gatsby";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh; /* Set the height to 100% of the viewport height */
  width: 100%; /* Set the width to 100% */
  margin: 0 auto;
  border-style: solid;
  border-color: #aaaaaa8e;
  border-width: 1px;
  background-color: #fbfbfb;
  overflow-y: auto; /* Enable vertical scrolling */

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  margin: 107px auto;
  flex-direction: column;
  max-width: 80vw;
  max-width: 1440px;
  padding: 55px;
  padding-top: 54px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 484px;

  @media (max-width: 500px) {
    margin-left: 15px;
    margin-right: 15px;
  }

  @media (max-width: 435px) {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Title = styled.h1`
  margin-top: 0;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #111111;

  @media (max-width: 400px) {
    font-size: 30px;
  }
`;
export const SubText = styled.h2`
  text-align: center;
  margin-bottom: 32px;
  margin-top: 16px;
  font-weight: 400;
  line-height: 26.1px;
  font-size: 18px;

  @media (max-width: 400px) {
    font-size: 16px;
  }
`;

export const InputHolder = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;

  align-items: center;
  margin-top: 10px;
  gap: 20px;

  @media (max-width: 435px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 0;
  }
`;

export const InputText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #111111;
  text-align: end;
  width: 40%;

  @media (max-width: 435px) {
    text-align: start;
    margin-left: 0;
    width: 100%;
  }
`;

export const InputField = styled.input`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  width: 70%;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #999999;

  @media (max-width: 435px) {
    width: 85%;
  }
`;

export const ForgotPassword = styled.p`
  text-align: end;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #007de6;
  cursor: pointer;
`;

export const LoginButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 16px;
  width: 100%;
  height: 50px;
  background: rgb(0, 202, 66);
  border-radius: 60px;
  margin: 16px 0px;
  border: 1px solid rgb(0, 202, 66);
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;

  :hover {
    cursor: pointer;
    background-color: #06e04f;
  }

  &:disabled {
    background-color: #cccccc;
    color: #999999;
    border: 1px solid #cccccc;
    cursor: not-allowed;
  }
`;

export const DontHaveAccountMessage = styled.p`
  margin-top: 25px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #222222;
`;

export const StyledLink = styled(Link)`
  color: #007de6;

  text-decoration: none;
`;

export const ErrorMessage = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: rgb(228, 67, 49);
  margin: 0px;
  padding: 0px;
`;

export const ErrorMessageHolder = styled.div`
  margin: 0px;
  padding: 0px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 35px;
`;

export const HideShowIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 16px; /* Adjust the right position as needed */
  transform: translateY(-50%);
  height: 32px;
  width: 32px;
  cursor: pointer;

  @media (max-width: 435px) {
    top: 75%;
    right: 9%;
  }
`;

export const CapsLockHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const On = styled.p`
  font-size: 14px;
  color: green;
`;

export const Off = styled.p`
  font-size: 14px;
  color: red;
`;

export const Caps = styled.p`
  font-size: 14px;
`;
