import React from "react";
import { Button, Wrapper } from "../../GlobalComponetns/Header/NavbarStyled";
import styled from "styled-components";
import UserDropdown from "./UserDropdown";
import { StyledLink } from "../../GlobalComponetns/Header/NavbarStyled";
import { useLocation } from "@reach/router";
import Navbar from "../../GlobalComponetns/Header/Navbar";
import { Link } from "gatsby";
import { ResolvePlan } from "../../../helpers/Dashboard";
import { useUserStore } from "../../ZustandStores/UserSession/UserStore";
const Header = styled(Wrapper)`
  width: 100%;
  box-sizing: border-box;
  position: relative;
`;

const InnerHolder = styled.div`
  width: 100%;
  max-width: 1472px;
  padding: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  gap: 40px;
`;

function DashboardHeader() {
  const location = useLocation();
  const { userLicenses } = useUserStore();
  const PlanType = ResolvePlan(userLicenses);
  return (
    <Header>
      {location.pathname === "/app/password-change/" ? (
        <Navbar menuIsOpen={false} />
      ) : (
        <InnerHolder>
          <StyledLink to="/blog"> Blog</StyledLink>
          <Link
            to={PlanType === "Trial" ? "/checkout" : "/app/manage-subscription"}
          >
            <Button>Checkout</Button>
          </Link>
          <UserDropdown />
        </InnerHolder>
      )}
    </Header>
  );
}

export default DashboardHeader;
