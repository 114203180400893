import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";

import TryPdf from "../../shared/Popups/TryPDFPopup/TryPdf";
import LoginPdf from "../../shared/Popups/Login/LoginPdf";
import MacPopup from "../../shared/Popups/macPopup/macPopup";
import usePopupsStore from "../../ZustandStores/PopupsStore";
import PasswordPrompt from "../../tools/PasswordPrompt";
function PopupResolver() {
  const location = useLocation();
  const { tryPdfOpen, setTryPdfOpen, loginPdfOpen } = usePopupsStore();
  const [isMac, setIsMac] = useState(false);
  const [isiPhone, setIsiPhone] = useState(false);

  useEffect(() => {
    setIsMac(navigator.platform.toUpperCase().indexOf("MAC") >= 0);
    setIsiPhone(/(iPhone|iPod)/.test(navigator.userAgent));
    if (typeof window !== "undefined") (window as any).setIsMac = setIsMac;
  }, []); // Run only once to set initial values

  useEffect(() => {
    const urlPattern = /(checkout)/;

    if (urlPattern.test(location.pathname) && (isMac || isiPhone)) {
      setTryPdfOpen(true);
    }
  }, [location, isMac, isiPhone]);

  //enable and disable isMac state in console using setIsMac(false), setIsMac(true)

  return (
    <div>
      {tryPdfOpen && !isMac && !isiPhone && <TryPdf />}
      {tryPdfOpen && (isMac || isiPhone) && (
        <MacPopup
          location={location}
          isMac={isMac}
          isiPhone={isiPhone}
          tryPdfOpen={tryPdfOpen}
          setTryPdfOpen={setTryPdfOpen}
        />
      )}
      {loginPdfOpen && <LoginPdf />}
      <PasswordPrompt name={"pswrt"} />
    </div>
  );
}

export default PopupResolver;
