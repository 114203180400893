import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Wrapper, Modal, Button } from "./PopupsStyled";
import usePopupsStore from "../ZustandStores/PopupsStore";

import { ReactComponent as Close } from "./Icons/close.svg";
import { useUserStore } from "../ZustandStores/UserSession/UserStore";
import useAuthStore from "../ZustandStores/UserSession/AuthenticationStore";

const ButtonDash = styled(Button)`
  width: 100%;
  margin-top: 32px;

  text-align: center;
  transition: 0.3s;
  ${(props) =>
    props.disabled &&
    `
  background-color: rgb(204, 204, 204);
  color: rgb(153, 153, 153);`}
`;

export const InputHolder = styled.div`
  display: flex;
  align-items: center;
`;
export const AddRemoveSignHolder = styled.div`
  padding: 5px 10px;
  background-color: #ececee;
  color: rgba(0, 0, 0, 0.4);
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  max-height: 36px;
`;
export const UserInput = styled.input`
  color: #36454f;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  max-width: 51px;
  text-align: center;
  border: 1px solid #ececee;
  background: #fff;
  max-height: 36px;
  min-height: 36px;

  box-sizing: border-box;
`;

export const CurrentLicenses = styled.h3`
  font-weight: 500;
`;

export const IncreaseFor = styled(CurrentLicenses)`
  margin-right: 16px;
`;

function IncreaseUsers() {
  const [Amount, setAmount] = useState(1);
  const [License, setLicense] = useState<any[]>([]);

  const { setIncreaseUsersOpen } = usePopupsStore();
  const { userLicenses, setUserLicenses } = useUserStore();
  const { authToken } = useAuthStore();

  function AvailableLicenses() {
    let availableLicenses = [];

    for (let i = 0; i < userLicenses.count; i++) {
      if (userLicenses.results[i].is_trial !== true) {
        availableLicenses.push(userLicenses.results[i]);
      }
    }

    setLicense(availableLicenses);
  }

  async function IncreseLicenses() {
    let quan = License.length + Amount;
    let subId = License[0]?.subscription_id;

    console.log("Token ", authToken);
    fetch("/api/increase-subscription-gatsby", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        subscriptionID: subId,
        quantity: quan,
      }),
    }).then(() => {
      fetch("/api/licenses-gatsby", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${authToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("new state: ", data.data);
          setUserLicenses(data.data);
          window.location.reload();
        });
    });
  }

  useEffect(() => {
    AvailableLicenses();
  }, [userLicenses]);

  useEffect(() => {
    console.log(License);
  }, [License]);

  const handleInputChange = (event: any) => {
    let Amount = parseInt(event.target.value);
    if (Number.isNaN(Amount) || Amount < 1) {
      Amount = 1;
    }
    setAmount(Amount);
  };

  return (
    <Wrapper>
      <Modal>
        <Close className="close" onClick={() => setIncreaseUsersOpen(false)} />
        <h1>Increse Number of Licenses</h1>
        <CurrentLicenses>
          Current Number of Licenses: {License.length}
        </CurrentLicenses>
        <InputHolder>
          <IncreaseFor>Increase for:</IncreaseFor>
          <AddRemoveSignHolder
            onClick={() => {
              if (Amount > 1) setAmount(Amount - 1);
            }}
          >
            -
          </AddRemoveSignHolder>
          <UserInput value={Amount} onChange={handleInputChange} />
          <AddRemoveSignHolder
            onClick={() => {
              setAmount(Amount + 1);
            }}
          >
            +
          </AddRemoveSignHolder>
        </InputHolder>

        <ButtonDash
          disabled={false}
          onClick={async () => {
            await IncreseLicenses();
            setIncreaseUsersOpen(false);
          }}
        >
          Confirm
        </ButtonDash>
      </Modal>
    </Wrapper>
  );
}

export default IncreaseUsers;
