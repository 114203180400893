export function ResolvePlan(userLicenses: any) {
  if (userLicenses?.results?.length === 0 && typeof window !== "undefined") {
    return "No License";
  }
  let position = userLicenses?.results?.length === 1 ? 0 : 1;

  if (userLicenses?.results) {
    if (userLicenses?.results[position]?.is_trial) {
      return "Trial";
    } else {
      return (
        userLicenses?.results[position]?.product.product_name +
        (userLicenses?.results[position]?.product_features[1]?.product_feature
          ?.name
          ? " + " +
            userLicenses?.results[position]?.product_features[1]
              ?.product_feature?.name
          : "")
      );
    }
  }
}

export function ResolveAccountType(userLicenses: any) {
  if (userLicenses?.results?.length === 0 && typeof window !== "undefined") {
    return "No License";
  }
  let position = userLicenses?.results?.length === 1 ? 0 : 1;

  let licenseCount = 0;
  let isTrial;

  if (typeof window !== "undefined") {
    isTrial =
      userLicenses?.results?.length === 0
        ? false
        : userLicenses?.results[position]?.is_trial;
  }

  for (let i = 0; i < userLicenses?.results?.length; i++) {
    if (
      userLicenses?.results[i].status === "Active" ||
      userLicenses?.results[i].license_type === "perpetual"
    ) {
      licenseCount++;
    }
  }
  if (licenseCount > 1 && !isTrial) {
    return "Multi User Account";
  }
  return "Single User Account";
}
