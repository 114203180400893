import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import { Wrapper, Container } from "./NavbarStyled";

import NavbarMobile from "./NavbarMobile";
import MainNavLinks from "./NavbarsLinks/MainNavLinks";
import CheckoutNavLinks from "./NavbarsLinks/CheckoutNavLinks";
import CheckoutNavLinksB from "./NavbarsLinks/CheckoutNavLinksB";
import CheckoutNavLinksC from "./NavbarsLinks/CheckoutNavLinksC";
import LoginPageLinks from "./NavbarsLinks/LoginPageLinks";
import DashboardLink from "./NavbarsLinks/DashBoardLink";

type NavbarProps = {
  menuIsOpen: boolean;
};

const Navbar: React.FC<NavbarProps> = () => {
  const [MenuIsOpen, setMenuIsOpen] = useState(false);
  const [urlPattern, setUrlPattern] = useState(/(checkout)/);
  const [lp9Pattern, setLp9Pattern] = useState(/(lp9)/);
  const [lp4Pattern, setLp4Pattern] = useState(/(lp4)/);

  useEffect(() => {
    setUrlPattern(/(checkout)/);
    setLp9Pattern(/(lp9)/);
    setLp4Pattern(/(lp4)/);
  }, []);

  const location = useLocation();

  const OpenMenuHandler: () => void = () => {
    setMenuIsOpen(!MenuIsOpen);
  };

  const closeMenuHandler: () => void = () => {
    setMenuIsOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 875 && MenuIsOpen) {
        setMenuIsOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [MenuIsOpen]);

  if (
    urlPattern.test(location.pathname) &&
    lp4Pattern.test(location.pathname)
  ) {
    return (
      <Wrapper checkout>
        <Container menuState={MenuIsOpen} checkout>
          <CheckoutNavLinksC />
        </Container>
      </Wrapper>
    );
  } else if (
    urlPattern.test(location.pathname) &&
    lp9Pattern.test(location.pathname)
  ) {
    return (
      <Wrapper checkout>
        <Container menuState={MenuIsOpen} checkout>
          <CheckoutNavLinksB />
        </Container>
      </Wrapper>
    );
  } else if (urlPattern.test(location.pathname)) {
    return (
      <Wrapper checkout>
        <Container menuState={MenuIsOpen} checkout>
          <CheckoutNavLinks />
        </Container>
      </Wrapper>
    );
  } else if (location.pathname === "/login/") {
    return (
      <Wrapper>
        <Container menuState={MenuIsOpen}>
          <LoginPageLinks
            MenuIsOpen={MenuIsOpen}
            closeMenuHandler={closeMenuHandler}
            OpenMenuHandler={OpenMenuHandler}
          />
        </Container>
        {MenuIsOpen ? <NavbarMobile onCloseMenu={closeMenuHandler} /> : null}
      </Wrapper>
    );
  } else if (location.pathname === "/dashboard/") {
    return (
      <Wrapper>
        <Container menuState={MenuIsOpen}>
          <DashboardLink
            MenuIsOpen={MenuIsOpen}
            closeMenuHandler={closeMenuHandler}
            OpenMenuHandler={OpenMenuHandler}
          />
        </Container>
        {MenuIsOpen ? <NavbarMobile onCloseMenu={closeMenuHandler} /> : null}
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <Container menuState={MenuIsOpen}>
          <MainNavLinks
            MenuIsOpen={MenuIsOpen}
            closeMenuHandler={closeMenuHandler}
            OpenMenuHandler={OpenMenuHandler}
          />
        </Container>
        {MenuIsOpen ? <NavbarMobile onCloseMenu={closeMenuHandler} /> : null}
      </Wrapper>
    );
  }
};

export default Navbar;
