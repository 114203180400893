import { getCookie } from "./Cookies";

export const WriteTrial = () => {
  const url = !(
    window.location.origin.includes("https://pdfpro.com") ||
    window.location.origin.includes("https://www.pdfpro.com") ||
    window.location.origin.includes("https://maintenance.pdfpro.com")
  )
    ? "https://staging-reporting.pdfpro.com/api/write-trial?token=ADMIN"
    : "https://reporting.pdfpro.com/api/write-trial?token=ADMIN";

  function getCurrentFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  let realSource =
    window.location.pathname === "/" ? "Home Page" : window.location.pathname;
  let realMedium = null;

  const referrer = document.referrer.toLowerCase();
  if (
    referrer.includes("google") ||
    referrer.includes("bing") ||
    referrer.includes("yahoo") ||
    referrer.includes("yandex") ||
    referrer.includes("duckduckgo") ||
    referrer.includes("baidu")
  ) {
    realMedium = "organic";
  } else {
    realMedium = "direct";
  }

  const data = {
    user_id: getCookie("user_id") ? getCookie("user_id") : "null",
    trial_utm_source: getCookie("utm_source")
      ? getCookie("utm_source")
      : "null",
    trial_utm_medium: getCookie("utm_medium")
      ? getCookie("utm_medium")
      : realMedium,
    trial_utm_content: getCookie("utm_content")
      ? getCookie("utm_content")
      : "null",
    trial_utm_term: getCookie("utm_term") ? getCookie("utm_term") : "null",
    trial_utm_campaign: getCookie("utm_campaign")
      ? getCookie("utm_campaign")
      : "null",
    trial_source: getCookie("source") ? getCookie("source") : realSource,
    time: getCurrentFormattedDate(), // Adding the current date
  };

  console.log("data: ", data);

  localStorage.setItem(
    "trial_utm_source",
    JSON.stringify(data.trial_utm_source)
  );
  localStorage.setItem(
    "trial_utm_medium",
    JSON.stringify(data.trial_utm_medium)
  );
  localStorage.setItem(
    "trial_utm_content",
    JSON.stringify(data.trial_utm_content)
  );
  localStorage.setItem("trial_utm_term", JSON.stringify(data.trial_utm_term));
  localStorage.setItem(
    "trial_utm_campaign",
    JSON.stringify(data.trial_utm_campaign)
  );

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }
      return response.json(); // or response.text() if the response is not JSON
    })
    .then((data) => {
      console.log("Success:", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};
