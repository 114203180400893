import React from "react";
import styled from "styled-components";
import { ReactComponent as RedirectSVG } from "./Icons/noun-redirect.svg";

const Style = styled.section`
  width: 100vw;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo-icon {
    margin-bottom: 24px;
    width: 258px;
    height: 258px;
    height: auto;
  }
  path {
    fill: rgb(228, 67, 49);
  }
`;

const Redirect = () => {
  return (
    <Style>
      <RedirectSVG className="logo-icon" />
      <h1>Redirecting...</h1>
    </Style>
  );
};

export default Redirect;
