import React from 'react';
import Layout from './src/components/Layout/Layout';
import PopupResolver from './src/components/GlobalComponetns/PopupResolver/PopupResolver';
import PrivateRouteDashboard from './src/components/shared/PrivateRoutes/PrivateRouteDashboard';
import BingScript from './src/components/shared/Scripts/Global/BingScript';
import { navigate } from 'gatsby';
import 'react-loading-skeleton/dist/skeleton.css';
export const onRouteUpdate = ({ location, prevLocation }) => {
  let previousSearchParams = '';
  let newHash = ''; // Variable to store the previous URL hash

  if (prevLocation) {
    previousSearchParams = prevLocation.search;
    newHash = location.hash;
  }

  const lpRegex = /lp\d+/;
  const rmRegex = /rm/;

  if (
    (lpRegex.test(location.pathname) || rmRegex.test(location.pathname)) &&
    location.search === '' &&
    previousSearchParams !== ''
  ) {
    console.log('browser: ', location);
    console.log('browser: ', prevLocation);

    navigate(`${location.pathname}${previousSearchParams}${newHash}`, {
      replace: true,
    });
  }
};

export const wrapPageElement = ({ element, props }) => {
  if (props.path.match(/^\/app/)) {
    return (
      <>
        <BingScript />
        {/* <GTMScript /> */}
        <PrivateRouteDashboard props={props} location={props.location}>
          {element}
        </PrivateRouteDashboard>
      </>
    );
  }

  return (
    <>
      <BingScript />
      {/* <GTMScript /> */}
      <Layout location={props.location}>{element}</Layout>
      <PopupResolver />
    </>
  );
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  console.log('browser: ', location);
  if (!location.href.includes('#')) {
    if (location.action === 'PUSH') {
      setTimeout(() => window.scrollTo(0, 0), 100);
      console.log('browser: ', location);
    } else {
      const savedPosition = getSavedScrollPosition(location);
      setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 100);
    }
  }
};
