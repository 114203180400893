import { create } from "zustand";

type TabStore = {
  activeTabsCheckout: number;
  setActiveTabCheckout: (id: number) => void;
};

const useActiveTabStoreCheckout = create<TabStore>((set) => {
  return {
    activeTabsCheckout: 1,
    setActiveTabCheckout: (id: number) => {
      set({ activeTabsCheckout: id });
    },
  };
});

export default useActiveTabStoreCheckout;
