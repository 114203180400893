import React, { useEffect } from "react";
import styled from "styled-components";

import { Wrapper, Modal, Button } from "./PopupsStyled";
import usePopupsStore from "../ZustandStores/PopupsStore";

import { ReactComponent as Close } from "./Icons/close.svg";
import { useUserStore } from "../ZustandStores/UserSession/UserStore";
import useAuthStore from "../ZustandStores/UserSession/AuthenticationStore";

const ButtonDash = styled(Button)`
  width: 100%;
  margin-top: 32px;

  text-align: center;
  transition: 0.3s;
  ${(props) =>
    props.disabled &&
    `
  background-color: rgb(204, 204, 204);
  color: rgb(153, 153, 153);`}
`;

const P = styled.p`
  margin-bottom: 0;
`;

function ChangeSubscription() {
  const { setSwitchSubscriptionOpen } = usePopupsStore();
  const { userLicenses, setUserLicenses, switchData } = useUserStore();
  const { authToken } = useAuthStore();

  async function SwitchSubscription() {
    let product = switchData?.newPath?.path;
    product = product.replace("-ocr", "");
    if (switchData.number > 1) {
      product = product + "-multi";
    }

    if (switchData?.newPath?.ocr) {
      product = product + "-ocr";
    }
    console.log(product);
    console.log(switchData.subscription);
    console.log(switchData.number);

    await fetch("/api/switch-subscriptions-gatsby", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        subscriptions: [
          {
            subscription: switchData.subscription,
            product: product,
            quantity: switchData.number,
            prorate: true,
          },
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        fetch("/api/licenses-gatsby", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setUserLicenses(data.data);
          });
      })
      .catch((error) => {
        console.error("Error assigning licenses:", error);
      });
  }

  useEffect(() => {
    console.log("switchData: ", switchData);
    // AvailableLicenses();
  }, [userLicenses]);

  return (
    <Wrapper>
      <Modal>
        <Close
          className="close"
          onClick={() => setSwitchSubscriptionOpen(false)}
        />
        <h1>Switch Subscription</h1>
        <P>
          Current Subscription:{" "}
          <b>
            {switchData?.oldPath?.name}{" "}
            {switchData?.oldPath?.ocr ? ", OCR" : ""}
          </b>
        </P>
        <P>
          <u>
            Changing to:{" "}
            <b>
              {switchData?.newPath?.name}{" "}
              {switchData?.newPath?.ocr ? ", OCR" : ""}
            </b>
          </u>
        </P>

        <P>
          Currently billed: <b>{switchData?.oldPath?.totalPriceFormated} </b>
        </P>
        <P>
          <u>
            New billing cycle price:{" "}
            <b>{switchData?.newPath?.totalPriceDiscountedFormated} </b>
          </u>
        </P>

        <ButtonDash
          disabled={false}
          onClick={async () => {
            await SwitchSubscription();
            setSwitchSubscriptionOpen(false);
          }}
        >
          Switch
        </ButtonDash>
      </Modal>
    </Wrapper>
  );
}

export default ChangeSubscription;
