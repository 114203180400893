import { create } from "zustand";

interface State {
  language: string;
  setLanguage: (lang: string) => void;
}

const useLanguage = create<State>((set) => ({
  language: "",
  setLanguage: (lang) => set({ language: lang }),
}));

export default useLanguage;
