import React from "react";
import { useEffect, useState } from "react";

import styled from "styled-components";
import { ReactComponent as Close } from "../../Icons/close-icon-popup.svg";
import { navigate } from "gatsby";

import { ReactComponent as AppleIcon } from "../../Icons/apple.svg";

const MacPopupStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  backdrop-filter: blur(8px);
  display: block;
  margin: auto auto;
  justify-content: center;
  align-items: center;
`;

const Holder = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  padding: 32px;
  gap: 16px;
  box-shadow: 0px 50px 100px -20px rgba(50, 50, 93, 0.25),
    0px 30px 60px -30px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  svg {
    margin-left: auto;
    cursor: pointer;
    color: #333333;
  }
`;

const InnerHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  padding: 32px;
  gap: 16px;
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 25px;
  line-height: 33px;
  letter-spacing: -0.03em;
  color: #333333;
  margin-bottom: 8px;
`;

const Row = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.03em;

  a {
    display: inline-block;
    color: #6361ff;
  }
`;

const PartnerButton = styled.button`
  all: unset;
  border-radius: 8px;
  background: #ed3833;
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;
  cursor: pointer;
  @media (max-width: 880px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media (max-width: 380px) {
    padding: 10px 12px;

    font-size: 14px;
    line-height: 14px;
  }
`;

const ContinueButton = styled.button`
  all: unset;
  border: 1px solid #ed3833;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  letter-spacing: -0.03em;
  color: #ed3833;
  cursor: pointer;
  @media (max-width: 880px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media (max-width: 380px) {
    padding: 10px 12px;

    font-size: 14px;
    line-height: 14px;
  }
`;

const ButtonRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  gap: 20px;
`;

const IconHolder = styled.div`
  width: 100px;
  height: 100px;
  margin-bottom: 24px;
  svg {
    min-width: 100%;
    min-height: 100%;
  }
`;

interface MacPopupProps {
  location: Location;
  isMac: boolean;
  isiPhone: boolean;
  tryPdfOpen: boolean;
  setTryPdfOpen: any;
}

function MacPopup({
  location,
  isMac,
  isiPhone,
  setTryPdfOpen,
}: MacPopupProps): JSX.Element {
  const [type, setType] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [link, setLink] = useState<string>("");

  useEffect(() => {
    if (isMac) {
      console.log("User is on a Mac");
      setType("Mac");
      setLink("http://www.pdfpro10.com/macpartner");
    }

    if (isiPhone) {
      console.log("User is on an iPhone");
      setType("iPhone");
      setLink("http://www.pdfpro10.com/macpartnermobile");
    }
    if (location.pathname === "/checkout/") {
      setText("Continue to PDF Pro Checkout");
    } else {
      setText("Remain on the pdfpro.com website");
    }
  }, [location]);

  return (
    <>
      <MacPopupStyle>
        <Holder>
          <Close
            id="CloseButton"
            onClick={() => {
              setTryPdfOpen(false);
            }}
          />
          <InnerHolder>
            <IconHolder>
              <AppleIcon />
            </IconHolder>
            <Title>Welcome {type} User!</Title>
            <Row>
              Welcome {type} User!{" "}
              <b>
                {" "}
                Unfortunately, PDF Pro isn’t compatible with Mac at this time.
              </b>{" "}
              However, for your benefit, we’ve partnered a great PDF editor for
              Mac, from a company we trust. You’ll be in good hands.
            </Row>
            <ButtonRow>
              <PartnerButton onClick={() => navigate(link)}>
                Visit Mac Partner page{" "}
              </PartnerButton>

              <ContinueButton
                onClick={() => {
                  setTryPdfOpen(false);
                }}
              >
                {text}
              </ContinueButton>
            </ButtonRow>
          </InnerHolder>
        </Holder>
      </MacPopupStyle>
    </>
  );
}

export default MacPopup;
