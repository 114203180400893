import React from "react";
import styled from "styled-components";

const LpFooter = () => {
  const Footer = styled.footer`
    background-color: #222222;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem;
    width: 100%;

    .footer-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 1440px;
      width: 90%;

      @media (max-width: 768px) {
        width: 95%;
      }
    }
  `;
  return (
    <Footer>
      <div className="footer-container">
        <p
          style={{
            fontStyle: `normal`,
            fontWeight: `normal`,
            fontSize: `12px`,
            lineHeight: `15px`,
            textAlign: `center`,
            color: `#DCDCDC`,
            height: `fit-content`,
            margin: `0.5rem 0`,
          }}
        >
          PDF Pro is a desktop software for Windows distributed by PDF Pro
          Software Inc. The software "PDF Pro" and the company "PDF Pro Software
          Inc." are in no way affiliated with Adobe. The PDF file format is an
          open file format published under ISO 32000-1:2008
        </p>
        <p
          style={{
            fontStyle: `normal`,
            fontWeight: `normal`,
            fontSize: `12px`,
            lineHeight: `2px`,
            color: `#DCDCDC`,
            height: `fit-content`,
            margin: `0.5rem 0`,
          }}
        >
          © 2017-{new Date().getFullYear()}, PDF Pro Software Inc. All rights
          reserved.
        </p>
      </div>
    </Footer>
  );
};

export default LpFooter;
