import { getCountry } from './Geolocator';
import { FastSpringPriceFiltered } from '../utils/FastSpringPricesFiltered';

export function formatNumber(number: string) {
  let NewNumber = parseFloat(number.replace(/[^0-9.-]+/g, '')).toFixed(0);
  return NewNumber;
}

export function formatPrice(price?: number | null): string {
  if (price === undefined || price === null) {
    return '';
  }

  const priceString = price.toFixed(2);
  if (priceString.endsWith('.00')) {
    return priceString.slice(0, -3);
  } else {
    return priceString;
  }
}

export function productInfo(
  path: string,
  amount: number,
  ProductDiscount: number | null = null,
  OcrDiscount: number | null = null,
  PathDiscount: string | null = null
) {
  if (!PathDiscount || !path.includes(PathDiscount)) {
    OcrDiscount = null;
    ProductDiscount = null;
  }
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let countryTag = getCountry(timezone)?.countryTag;

  let Product = FastSpringPriceFiltered[path]?.pricing[countryTag];
  let RootProduct =
    FastSpringPriceFiltered[path.replace('-ocr', '')]?.pricing[countryTag];
  let OcrProduct = path.includes('-ocr')
    ? FastSpringPriceFiltered[path]?.pricing[countryTag]
    : FastSpringPriceFiltered[path + '-ocr']?.pricing[countryTag];

  if (path.includes('-reactivation')) {
    OcrProduct = path.includes('-ocr')
      ? FastSpringPriceFiltered[path]?.pricing[countryTag]
      : FastSpringPriceFiltered[
          path.replace('-reactivation', '-ocr-reactivation')
        ]?.pricing[countryTag];
  }

  let Ocr = path.includes('-ocr') ? true : false;

  let Currency = determineLocalCurrencyBasedOnAllowedCurrencies(path);

  let RealOcrDiscount = OcrDiscount && 1 - OcrDiscount;

  let OcrDiscounted = !RealOcrDiscount
    ? parseFloat(
        formatPrice(
          OcrProduct?.quantityDiscount[1]?.unitPrice -
            (RootProduct?.quantityDiscount[1]?.unitPrice
              ? RootProduct?.quantityDiscount[1]?.unitPrice
              : RootProduct?.price)
        )
      )
    : parseFloat(
        formatPrice((OcrProduct?.price - RootProduct?.price) * RealOcrDiscount)
      );

  let RealProductDiscount = ProductDiscount && 1 - ProductDiscount;

  let ProductDiscounted = RealProductDiscount
    ? parseFloat(formatPrice(RootProduct?.price * RealProductDiscount))
    : parseFloat(
        formatPrice(
          RootProduct?.quantityDiscount[1]?.unitPrice
            ? RootProduct?.quantityDiscount[1]?.unitPrice
            : RootProduct?.price
        )
      );

  let info = {
    currencyName: RootProduct?.currency,
    currency: Currency,
    monthlyPriceDiscounted:
      Currency +
      formatPrice(
        path.includes('one-year')
          ? ProductDiscounted / 12
          : ProductDiscounted / 24
      ),
    monthlyPrice:
      Currency +
      formatPrice(
        path.includes('one-year')
          ? RootProduct?.price / 12
          : RootProduct?.price / 24
      ),
    productPrice: formatPrice(RootProduct?.price),
    productDiscounted: ProductDiscounted,
    productDiscountedWithAddons: Ocr
      ? ProductDiscounted + OcrDiscounted
      : ProductDiscounted,
    productWithAddons: Ocr
      ? parseFloat(formatPrice(RootProduct?.price)) +
        parseFloat(formatPrice(OcrProduct?.price - RootProduct?.price))
      : parseFloat(formatPrice(RootProduct?.price)),
    ocr: Currency + formatPrice(OcrProduct?.price - RootProduct?.price),
    ocrDiscounted: OcrDiscounted,
    ocrDiscountedFormated: Currency + OcrDiscounted,
  };

  return {
    ...info,
    monthlyPriceDiscounted: path.includes('monthly')
      ? Currency + formatPrice(Product?.price * amount)
      : info?.monthlyPriceDiscounted,
    monthlyPrice: path.includes('monthly') ? null : info?.monthlyPrice,
    monthlyOcrPrice:
      Currency + parseFloat(formatPrice((Product?.price * amount) / 12)),
    monthlyOcrPriceDiscounted:
      Currency +
      parseFloat(
        formatPrice((info?.productDiscountedWithAddons * amount) / 12)
      ),
    productDiscounted: info?.productDiscounted,
    productPrice: parseFloat(info?.productPrice),

    totalPriceDiscounted: parseFloat(
      formatPrice(info?.productDiscountedWithAddons * amount)
    ),
    totalPrice: parseFloat(formatPrice(Product?.price * amount)),

    //formatedNumbers
    productDiscountedFormated: Currency + info?.productDiscounted,
    productPriceFormated: Currency + formatNumber(info?.productPrice),
    productDiscountedWithAddonsFormated:
      Currency + info?.productDiscountedWithAddons,
    productWithAddonsFormated:
      info?.productDiscountedWithAddons !== info?.productWithAddons
        ? Currency + info?.productWithAddons
        : null,
    totalPriceDiscountedFormated:
      Currency + formatPrice(info?.productDiscountedWithAddons * amount),
    totalPriceFormated:
      formatPrice(Product?.price * amount) !==
      formatPrice(info?.productDiscountedWithAddons * amount)
        ? Currency + formatPrice(Product?.price * amount)
        : null,
    finalProductPrice: parseFloat(formatPrice(Product?.price)),
    finalProductDiscount:
      parseFloat(formatPrice(Product?.price)) -
      parseFloat(formatPrice(info?.productDiscountedWithAddons)),
  };
}

export function determineLocalCurrencyBasedOnAllowedCurrencies(
  productPath: string
): any {
  if (productPath) {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let countryTag = getCountry(timezone)?.countryTag;

    let filteredProduct =
      FastSpringPriceFiltered[productPath].pricing[countryTag];

    if (filteredProduct?.currency?.toLowerCase() === 'USD'.toLowerCase()) {
      return '$';
    } else if (
      filteredProduct?.currency?.toLowerCase() === 'AUD'.toLowerCase()
    ) {
      return '$';
    } else if (
      filteredProduct?.currency?.toLowerCase() === 'CAD'.toLowerCase()
    ) {
      return '$';
    } else if (
      filteredProduct?.currency?.toLowerCase() === 'EUR'.toLowerCase()
    ) {
      return '€';
    } else if (
      filteredProduct?.currency?.toLowerCase() === 'GBP'.toLowerCase()
    ) {
      return '£';
    }

    return 'Currency not supported';
  }
}

export function countryInfo() {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let countryTag = getCountry(timezone)?.countryTag;
  let countryName = getCountry(timezone)?.countryName;

  let product = 'pdf-one-year';
  let filteredProduct = FastSpringPriceFiltered[product]?.pricing[countryTag];

  return {
    countryTag: countryTag,
    countryName: countryName,
    currency: filteredProduct?.currency,
  };
}
