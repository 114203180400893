import React from "react";
import styled from "styled-components";
import { ReactComponent as Windows } from "../Icons/Windows.svg";
import { ReactComponent as WindowsSmall } from "../Icons/Windows-Small.svg";

import { ReactComponent as Close } from "../Icons/Close_LG.svg";
import usePopupsStore from "../../ZustandStores/PopupsStore";

interface BannerHolderProps {
  visible: boolean;
}
const BannerHolder = styled.div<BannerHolderProps>`
  background: #4d73e8;
  width: 100vw;
  padding: 16px 0;
  transition: padding 0.5s ease-in-out, height 0.5s ease-in-out;
  height: 0;
  padding: 0;
  overflow: hidden;
  ${(props) => props.visible && `height: auto; padding: 16px 0;`}

  #close {
    position: relative;
    right: -96%;
    @media (max-width: 1440px) {
      right: -96%;
    }
    top: -4px;
    max-width: 0;
    max-height: 0;
    cursor: pointer;
    svg {
      min-width: 24px;
      min-height: 24px;
    }

    @media (max-width: 1240px) {
      right: -94%;
    }

    @media (max-width: 800px) {
      right: -93%;
      svg {
        scale: 0.8;
      }
    }

    @media (max-width: 640px) {
      right: -92%;
      svg {
        scale: 0.8;
      }
    }

    @media (max-width: 540px) {
      right: -90%;
      svg {
        scale: 0.8;
      }
    }

    @media (max-width: 420px) {
      top: -12px;
      right: -88%;
      svg {
        scale: 0.7;
      }
    }
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const BannerHolderMobile = styled(BannerHolder)`
  flex-direction: column;
  display: none;

  @media (max-width: 800px) {
    display: block;

    #window {
      max-width: 48px !important;
      min-width: 48px !important;
    }
  }
`;
const Wrapper = styled.div`
  padding: 16px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
  @media (min-width: 1440px) {
    padding: 0 24px;
  }

  width: 80%;

  #window {
    min-width: 78px;
  }
`;

const CtaTitle = styled.div`
  color: #fff;
  font-size: 28.601px;
  font-style: normal;
  font-weight: 700;
  line-height: 36.772px; /* 128.571% */
  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 27px; /* 150% */
    width: 100%;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  @media (max-width: 800px) {
    margin-left: 0;
  }
`;
const UnderTitle = styled.div`
  color: #fff;

  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 128.571% */

  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 27px; /* 150% */
    width: 100%;
  }
`;

const Button = styled.button`
  color: #e6f6eb;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #4d73e8;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-left: auto;
  cursor: pointer;
  max-height: 61px;
  margin-left: 24px;

  @media (max-width: 1100px) {
    margin: 0;
    margin-left: 24px;
    margin-top: 16px;
  }
  @media (max-width: 800px) {
    width: 100%;
    padding: 8px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px; /* 150% */
    margin-left: 0;
  }
  @media (max-width: 365px) {
    white-space: wrap;
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1100px) {
    flex-direction: column;

    align-items: flex-start;
  }
`;
const RowMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0;
  width: 100%;
`;
function ToolsCta() {
  const [show, setShow] = React.useState(true);
  const [visible, setVisible] = React.useState(false);
  // Initialize state for OS compatibility
  const [isIOS, setIsMac] = React.useState(false);

  const { setTryPdfOpen, ctaOnlineTools } = usePopupsStore();

  React.useEffect(() => {
    // Check for Mac or Android only once when the component mounts
    const isIOSOrAndroid = /Mac|Android/.test(navigator.userAgent);
    setIsMac(isIOSOrAndroid);
  }, []);

  React.useEffect(() => {
    if (ctaOnlineTools) setShow(true);
    setVisible(ctaOnlineTools);
  }, [ctaOnlineTools]);

  React.useEffect(() => {
    if (!visible) setTimeout(() => setShow(false), 500);
  }, [visible]);

  if (isIOS) return null;

  return show || visible ? (
    <>
      <BannerHolder visible={visible}>
        <div id="close">
          <Close onClick={() => setVisible(false)} />
        </div>
        <Wrapper>
          <Windows id={"window"} />
          <Row>
            <Column>
              <CtaTitle>Are you enjoying our online tools?</CtaTitle>
              <UnderTitle>
                Easily work offline with our all-in-one PDF editor for desktop.
                Try 15 days risk-free.
              </UnderTitle>
            </Column>
            <Button
              onClick={() => {
                setTryPdfOpen(true);
              }}
            >
              Download PDF Pro for Windows
            </Button>
          </Row>
        </Wrapper>{" "}
      </BannerHolder>

      <BannerHolderMobile visible={visible}>
        <div id="close">
          <Close onClick={() => setVisible(false)} />
        </div>
        <Wrapper>
          <Row>
            <Column>
              <RowMobile>
                <WindowsSmall id={"window"} />
                <CtaTitle>Are you enjoying our online tools?</CtaTitle>
              </RowMobile>
              <UnderTitle>
                Easily work offline with our all-in-one PDF editor for desktop.
                Try 15 days risk-free.
              </UnderTitle>
            </Column>
            <Button
              onClick={() => {
                setTryPdfOpen(true);
              }}
            >
              {" "}
              Try PDF Pro for Windows
            </Button>
          </Row>
        </Wrapper>{" "}
      </BannerHolderMobile>
    </>
  ) : null;
}

export default ToolsCta;
