import { create } from "zustand";

type ModalState = {
  tryPdfOpen: boolean;
  setTryPdfOpen: (open: boolean) => void;
  assignUserOpen: boolean;
  setAssignUserOpen: (open: boolean) => void;
  increaseUsersOpen: boolean;
  setIncreaseUsersOpen: (open: boolean) => void;
  assignManagerOpen: boolean;
  setAssignManagerOpen: (open: boolean) => void;
  switchSubscriptionOpen: boolean;
  setSwitchSubscriptionOpen: (open: boolean) => void;
  loginPdfOpen: boolean;
  setLoginPdfOpen: (open: boolean) => void;
  ctaOnlineTools: boolean;
  setCtaOnlineTools: (open: boolean) => void;
};

const usePopupsStore = create<ModalState>((set) => ({
  loginPdfOpen: false,
  setLoginPdfOpen: (open: boolean) => set({ loginPdfOpen: open }),
  tryPdfOpen: false,
  setTryPdfOpen: (open: boolean) => set({ tryPdfOpen: open }),
  assignUserOpen: false,
  setAssignUserOpen: (open: boolean) => set({ assignUserOpen: open }),
  increaseUsersOpen: false,
  setIncreaseUsersOpen: (open: boolean) => set({ increaseUsersOpen: open }),
  assignManagerOpen: false,
  setAssignManagerOpen: (open: boolean) => set({ assignManagerOpen: open }),
  switchSubscriptionOpen: false,
  setSwitchSubscriptionOpen: (open: boolean) =>
    set({ switchSubscriptionOpen: open }),
  ctaOnlineTools: false,
  setCtaOnlineTools: (open: boolean) => set({ ctaOnlineTools: open }),
}));

export default usePopupsStore;
