import React, { FC, useEffect } from "react";
import {
  LinksContainer,
  AncerLink,
  StyledLink,
  HamburgerHolder,
} from "../NavbarStyled";
import HamburgerMenue from "../../Icons/Hamburger.svg";
import CloseMenue from "../../Icons/Close icon.svg";
import useAuthStore from "../../../ZustandStores/UserSession/AuthenticationStore";
import { useUserStore } from "../../../ZustandStores/UserSession/UserStore";

export interface NavbarProps {
  MenuIsOpen: boolean;
  closeMenuHandler: () => void;
  OpenMenuHandler: () => void;
}

const MainNavLinks: FC<NavbarProps> = ({
  MenuIsOpen,
  closeMenuHandler,
  OpenMenuHandler,
}) => {
  const { authToken, clearAuthToken } = useAuthStore();
  const { userDetails } = useUserStore();

  useEffect(() => {
    console.log(userDetails);
  }, []);
  return (
    <>
      <StyledLink to="/">
        {/* <Logo src={PDFLogo} alt="PDF Pro Logo" onClick={closeMenuHandler} /> */}
      </StyledLink>

      <LinksContainer menuState={MenuIsOpen}>
        {!authToken ? (
          <>
            <StyledLink to="/features">Features</StyledLink>
            <AncerLink
              href="https://support.pdfpro.com/hc/en-us/requests/new"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support
            </AncerLink>
            <StyledLink to="/blog">Blog</StyledLink>
            <StyledLink to="/checkout">Pricing</StyledLink>

            {/* <AncerLink href="/Checkout">
              <Button>Pricing</Button>
            </AncerLink> */}
          </>
        ) : (
          <>
            <StyledLink to="/blog">Blog</StyledLink>
            <StyledLink to="/app/dashboard">Dashboard</StyledLink>
            <StyledLink to="/">{userDetails.first_name}</StyledLink>
          </>
        )}

        {!authToken ? (
          <StyledLink to="/login">Login</StyledLink>
        ) : (
          <StyledLink
            to="/login"
            onClick={() => {
              clearAuthToken();
            }}
          >
            Sign Out
          </StyledLink>
        )}
      </LinksContainer>
      <HamburgerHolder onClick={OpenMenuHandler} menuState={MenuIsOpen}>
        {!MenuIsOpen ? (
          <img src={HamburgerMenue} alt="hamburger menu" />
        ) : (
          <img src={CloseMenue} alt="close" />
        )}
      </HamburgerHolder>
    </>
  );
};

export default MainNavLinks;
