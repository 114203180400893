export function RouteResolver(
  location: any,
  match: string,
  exact: boolean = false
) {
  if (location) {
    if (!exact) {
      if (location.pathname.includes(match)) {
        return true;
      }
    } else {
      if (location.pathname === match) {
        return true;
      }
    }
  }
  return false;
}
