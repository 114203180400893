import React, { FC } from "react";
import {
  Container,
  LinkHolderMobile,
  Button,
  MobileNavLinkToCheckout,
} from "./NavbarMobileStyled";
import { AncerLink, StyledLink } from "./NavbarStyled";

interface NavbarMobileProps {
  onCloseMenu: () => void;
}

const NavbarMobile: FC<NavbarMobileProps> = ({ onCloseMenu }) => {
  const closeNavberHandler = () => {
    onCloseMenu();
  };

  return (
    <Container>
      <LinkHolderMobile>
        <div>
          <StyledLink to="/features" onClick={closeNavberHandler}>
            FEATURES
          </StyledLink>
          <AncerLink
            href="https://support.pdfpro.com/hc/en-us/sections/4407459740569-FAQ"
            target="_blank"
            rel="noopener noreferrer"
            onClick={closeNavberHandler}
          >
            SUPPORT
          </AncerLink>
          <StyledLink to="/blog" onClick={closeNavberHandler}>
            BLOG
          </StyledLink>
          <StyledLink to="/login" onClick={closeNavberHandler}>
            LOGIN
          </StyledLink>
          <StyledLink to="/register" onClick={closeNavberHandler}>
            CREATE ACCOUNT
          </StyledLink>
        </div>
        <MobileNavLinkToCheckout to="/checkout" onClick={closeNavberHandler}>
          <Button>Pricing</Button>
        </MobileNavLinkToCheckout>
      </LinkHolderMobile>
    </Container>
  );
};

export default NavbarMobile;
