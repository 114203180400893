import { Link } from "gatsby";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const LinkHolderMobile = styled.div`
  position: absolute;
  top: 70px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: space-between; /* Adjusted this */
  align-items: flex-start;
  padding: 5%;

  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 50px;

    @media (max-height: 556px) {
      gap: 10px;
    }
  }

  @media (max-height: 320px) {
    height: 80vh;
    overflow-y: scroll;
  }
`;

export const Button = styled.button`
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  align-items: center;
  width: 160px;
  height: 42px;
  background: rgb(228, 67, 49);
  border-radius: 60px;
  margin: 0px;
  padding: 0px;
  color: white;
  border: none;

  margin-bottom: 144px;

  @media (max-height: 320px) {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  align-self: center;
`;

export const MobileNavLinkToCheckout = styled(Link)`
  margin: 0px;
  padding: 0px;
  align-self: center;
`;
