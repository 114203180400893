import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Wrapper, Modal, Button } from "./PopupsStyled";
import usePopupsStore from "../ZustandStores/PopupsStore";
import {
  InputHolder,
  InputText,
  InputField,
  // HideShowIcon,
} from "../ValidateUser/ValidateUserStyled";
// import { ReactComponent as ShowEye } from "./Icons/eye.svg";
// import { ReactComponent as HideEye } from "./Icons/eyeOff.svg";
import { ReactComponent as Close } from "./Icons/close.svg";
import { useUserStore } from "../ZustandStores/UserSession/UserStore";
import useAuthStore from "../ZustandStores/UserSession/AuthenticationStore";
import { validateEmail } from "../../utils/validationUtils";

const InputHolderColumn = styled(InputHolder)`
  flex-direction: column;
  gap: 0;
  align-items: flex-end;
  width: 100%;
`;

const WrapperManager = styled(Wrapper)`
  position: fixed;
  top: 0;
  left: 0;
`;

const TextFieldDash = styled(InputText)`
  justify-content: flex-start;
  text-align: start;
  width: 100%;
`;

const InputFieldDash = styled(InputField)`
  width: 100%;
  box-sizing: border-box;
`;

// const HideShowIconDash = styled(HideShowIcon)`
//   top: 75%;
// `;

const ButtonDash = styled(Button)`
  width: 100%;
  min-height: 42px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  transition: 0.3s;
  ${(props) =>
    props.disabled &&
    `
  background-color: rgb(204, 204, 204);
  color: rgb(153, 153, 153);`}
`;
type ErrorStyleProps = {
  visible: boolean;
};

const ErrorStyle = styled.div<ErrorStyleProps>`
  min-height: 26px;

  color: red;
  font-size: 12px;
  translate: 0 8px;
  ${(props) => (props.visible ? "opacity: 1" : "opacity: 0")};
`;

/*
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingCircle = styled.div`
  padding: 8px;
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid white;
  border-color: white transparent white transparent;
  animation: ${rotate} 1.2s linear infinite;
`;
*/

function AssignNewManager() {
  // const [showPassword, setShowPassword] = useState(false);
  const [Email, setEmail] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  // const [PhoneNumer, setPhoneNumber] = useState("");
  // const [Password, setPassword] = useState("");
  const [License, setLicense] = useState<any[]>([]);
  const [Error, setError] = useState("");
  // const [Loading, setLoading] = useState(false);

  const { setAssignManagerOpen } = usePopupsStore();
  const { userLicenses, setUserLicenses } = useUserStore();
  const { authToken } = useAuthStore();

  function AvailableLicenses() {
    let availableLicenses = [];
    let unavailableLicenses = [];

    for (let i = 0; i < userLicenses.count; i++) {
      //checks if the license is available
      if (
        userLicenses.results[i].license_users.length === 0 &&
        userLicenses.results[i].is_trial !== true
      ) {
        availableLicenses.push(userLicenses.results[i]);
      } else if (userLicenses.results[i].is_trial !== true) {
        unavailableLicenses.push(userLicenses.results[i]);
      }
    }

    setLicense(availableLicenses);
  }

  async function AssignLicenseManager() {
    const licensesId = License[0].order;
    console.log(licensesId);

    await fetch("/api/assign-license-manager-gatsby", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authToken}`,
      },
      body: JSON.stringify({
        licenseID: licensesId,
        email: Email,
        fname: FirstName,
        lname: LastName,
        // numberUser: PhoneNumer,
        // password: Password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        fetch("/api/licenses-gatsby", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setUserLicenses(data.data);
          });
      })
      .catch((error) => {
        console.error("Error assigning licenses:", error);
      });
  }

  useEffect(() => {
    AvailableLicenses();
  }, [userLicenses]);

  let Disabled =
    Email.length === 0 || FirstName.length === 0 || LastName.length === 0;
  //  ||
  // PhoneNumer.length === 0 ||
  // Password.length === 0;

  console.log(
    Disabled,
    Email.length === 0,
    FirstName.length === 0,
    LastName.length === 0
    // PhoneNumer.length === 0,
    // Password.length === 0
  );
  return (
    <WrapperManager>
      <Modal>
        <Close className="close" onClick={() => setAssignManagerOpen(false)} />
        <h1>Assign New Licenses Administrator</h1>
        <InputHolderColumn>
          <TextFieldDash>Email</TextFieldDash>
          <InputFieldDash
            placeholder="Enter Email"
            type="text"
            value={Email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </InputHolderColumn>
        <InputHolderColumn>
          <TextFieldDash>First Name</TextFieldDash>
          <InputFieldDash
            placeholder="Enter First Name"
            type="text"
            value={FirstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </InputHolderColumn>
        <InputHolderColumn>
          <TextFieldDash>Last Name</TextFieldDash>
          <InputFieldDash
            placeholder="Enter Last Name"
            type="text"
            value={LastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </InputHolderColumn>
        {/* <InputHolderColumn>
          <TextFieldDash>Phone Number</TextFieldDash>
          <InputFieldDash
            placeholder="Enter Phone Number"
            type="text"
            value={PhoneNumer}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
        </InputHolderColumn> */}
        {/* <InputHolderColumn>
          <TextFieldDash>Password</TextFieldDash>
          <InputFieldDash
            placeholder="Enter Password"
            type={showPassword ? "text" : "password"}
            value={Password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {showPassword ? (
            <HideShowIconDash>
              <ShowEye onClick={() => setShowPassword(!showPassword)} />
            </HideShowIconDash>
          ) : (
            <HideShowIconDash>
              <HideEye onClick={() => setShowPassword(!showPassword)} />
            </HideShowIconDash>
          )}
        </InputHolderColumn> */}
        <ErrorStyle visible={Error !== ""}> {Error}</ErrorStyle>
        <ButtonDash
          disabled={Disabled}
          onClick={async () => {
            if (validateEmail(Email)) {
              // const encoded = encodeURIComponent(Email);
              // setLoading(true);

              // await fetch(`/api/check-email-if-taken?email=${encoded}`, {
              //   method: "GET",
              //   headers: {
              //     "Content-Type": "application/json",
              //     Authorization: `Token ${authToken}`,
              //   },
              // })
              //   .then((response) => response.json())
              //   .then(async (data) => {
              //     console.log("DATA: ", data);
              //     if (data.valid) {

              //     } else {
              //       setError("Email is already taken");
              //       setLoading(false);

              //     }
              //   });

              await AssignLicenseManager();
              setAssignManagerOpen(false);
            } else {
              setError("Please enter a valid email");
            }
          }}
        >
          {/* {!Loading && "Assign"} */}
          Assign
          {/* {Loading && <LoadingCircle />} */}
        </ButtonDash>
      </Modal>
    </WrapperManager>
  );
}

export default AssignNewManager;
