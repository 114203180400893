import React from "react";
import { navigate } from "gatsby";

import useAuthStore from "../../ZustandStores/UserSession/AuthenticationStore";
import Redirect from "../Redirect";
import Layout from "../../Layout/Layout";
import LayoutDashboard from "../../Dashboard/LayoutDashboard";

function PrivateRouteDashboard({
  children,
  props,
  location,
}: {
  children: any;
  props: any;
  location: any;
}) {
  // This is a dummy check. Replace with your actual authentication check.
  const { authToken } = useAuthStore();

  //   useEffect(() => {}, [isLogged]);
  if (!authToken) {
    navigate("/login/");
  }

  return authToken ? (
    <LayoutDashboard location={location}>{children}</LayoutDashboard>
  ) : (
    <Layout {...props}>
      <Redirect />
    </Layout>
  );
}

export default PrivateRouteDashboard;
