import React, { FC, useEffect, useState } from 'react';
import {
  LinksContainer,
  AncerLink,
  StyledLink,
  Button,
  HamburgerHolder,
} from '../NavbarStyled';
import { ReactComponent as PDFLogo } from '../../Icons/Logo PDF Pro 1.svg';
import { ReactComponent as HamburgerMenue } from '../../Icons/Hamburger.svg';
import { ReactComponent as CloseMenue } from '../../Icons/Close icon.svg';
import useAuthStore from '../../../ZustandStores/UserSession/AuthenticationStore';
import { useUserStore } from '../../../ZustandStores/UserSession/UserStore';
import UserDropdown from '../../../Dashboard/DashboardMenu/UserDropdown';
import { ResolvePlan } from '../../../../helpers/Dashboard';

import Skeleton from 'react-loading-skeleton';

export interface NavbarProps {
  MenuIsOpen: boolean;
  closeMenuHandler: () => void;
  OpenMenuHandler: () => void;
}

const MainNavLinks: FC<NavbarProps> = ({
  MenuIsOpen,
  closeMenuHandler,
  OpenMenuHandler,
}) => {
  const { authToken } = useAuthStore();
  const { userLicenses } = useUserStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const PlanType = ResolvePlan(userLicenses);
  return (
    <>
      <StyledLink to="/" aria-label="Home" className="nav-link">
        <PDFLogo onClick={closeMenuHandler} />
      </StyledLink>
      <LinksContainer menuState={MenuIsOpen}>
        {!authToken && !loading && (
          <>
            <StyledLink to="/features">Features</StyledLink>
            <AncerLink
              href="https://support.pdfpro.com/hc/en-us/requests/new"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support
            </AncerLink>
            <StyledLink to="/blog">Blog</StyledLink>
            <StyledLink to="/checkout">Pricing</StyledLink>

            {/* <StyledLink to="/checkout">
              <Button>Pricing</Button>
            </StyledLink> */}
          </>
        )}
        {authToken && !loading && (
          <>
            <StyledLink to="/blog">Blog</StyledLink>
            <StyledLink
              to={
                PlanType === 'Trial' ? '/checkout' : '/app/manage-subscription'
              }
            >
              <Button>Checkout</Button>
            </StyledLink>
            <StyledLink to="/">
              <UserDropdown />
            </StyledLink>
          </>
        )}

        {loading && (
          <div
            style={{
              minWidth: '25vw',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '12px',
            }}
          >
            <div
              style={{
                width: '32%',
              }}
            >
              <Skeleton style={{ width: '100%', height: '32px' }} />
            </div>
            <div
              style={{
                width: '32%',
              }}
            >
              <Skeleton style={{ width: '100%', height: '32px' }} />
            </div>
            <div
              style={{
                width: '32%',
              }}
            >
              <Skeleton style={{ width: '100%', height: '32px' }} />
            </div>
          </div>
        )}

        {/*Helper Component that will display component depending on authToken */}
        {!authToken ? <StyledLink to="/login">Login</StyledLink> : null}
        {!authToken ? (
          <StyledLink to="/register">Create Account</StyledLink>
        ) : null}
      </LinksContainer>
      <HamburgerHolder onClick={OpenMenuHandler} menuState={MenuIsOpen}>
        {!MenuIsOpen ? <HamburgerMenue /> : <CloseMenue />}
      </HamburgerHolder>
    </>
  );
};

export default MainNavLinks;
